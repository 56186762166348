export const SET_AUTHENTICATION_STATUS = 'SET_AUTHENTICATION_STATUS';
export const SET_AUTHENTICATION_STATUS_NS =
  'authentication/' + SET_AUTHENTICATION_STATUS;
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CURRENT_USER_NS = 'authentication/' + SET_CURRENT_USER;
export const SET_TRIAL_STATUS = 'SET_TRIAL_STATUS';
export const SET_TRIAL_STATUS_NS = `authentication/${SET_TRIAL_STATUS}`;
export const SET_SESSION_EXPIRY = 'SET_SESSION_EXPIRY';
export const SET_UNKNOWN_ERROR = 'SET_UNKNOWN_ERROR';
export const SET_SETUP_GUIDE_VISIBILITY = 'SET_SETUP_GUIDE_VISIBILITY';
export const SET_DOMAINS = 'SET_DOMAINS';
export const SET_SETUP_GUIDE_COLLAPESED = 'SET_SETUP_GUIDE_COLLAPESED';
