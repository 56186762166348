<template>
  <transition name="toast">
    <div class="toast-message" v-if="toast" v-test:toast>
      <p
        class="toast-message__content"
        role="alert"
        aria-live="assertive"
        @click="$emit('removeToastMessage')"
      >
        <span v-html="toast.message" />

        <router-link
          v-if="toast.route"
          class="button button--primary"
          :to="toast.route"
          ><translate>View</translate></router-link
        >
      </p>
    </div>
  </transition>
</template>

<script>
  export default {
    props: {
      toast: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        activeTimeout: null,
      };
    },
    watch: {
      toast() {
        clearTimeout(this.activeTimeout);

        if (this.toast && this.toast.message) {
          this.activeTimeout = setTimeout(() => {
            this.$emit('removeToastMessage');
          }, 3000);
        }
      },
    },
  };
</script>

<style src="./ToastMessage.scss" lang="scss"></style>
