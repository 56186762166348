import { DirectiveBinding, ObjectDirective } from 'vue';

const testDirective: ObjectDirective = {
  inserted(el: HTMLElement, binding: DirectiveBinding<string>) {
    const environment = process.env.NODE_ENV ?? 'unknown';
    if (!['development', 'test'].includes(environment)) return;
    if (!binding.arg) throw new Error('v-test needs an argument');
    el.setAttribute(
      'data-test',
      binding.value ? `${binding.arg}:${binding.value}` : binding.arg
    );
  },
};
export default testDirective;
