import api from '../../../api';
import axios from 'axios';
import * as types from '../mail-mutation-types';
import { ATTACHMENT_IS_TOO_BIG } from '@/api/error-codes';

// 26214400 bytes = 25 MiB. A 25 MB limit is published at
// https://support.startmail.com/hc/en-us/articles/360007454497
export const MAX_ATTACHMENTS_SIZE = 26214400;

let attachmentId = 1;

const state = {
  createFromMessage: null,
  draft: {},
  attachments: [],
  attachmentUploads: {},
  sending: false,
  keyState: {},
};

const getters = {
  encrypted: (state) => state.draft.encrypted,
  attachmentIds: (state) =>
    state.attachments.map((attachment) => attachment.id),
  allAttachments: (state) =>
    state.attachments.map((attachment) => {
      if (state.attachmentUploads[attachment.id]) {
        return { ...attachment, ...state.attachmentUploads[attachment.id] };
      } else {
        return { ...attachment, progress: 1, cancelToken: null, state: 'done' };
      }
    }),
  attachments: (state, { allAttachments }) =>
    allAttachments.filter((attachment) => !attachment.inline),
  inlineAttachments: (state, { allAttachments }) =>
    allAttachments.filter((attachment) => attachment.inline),
  totalAttachmentsSize: (state) =>
    state.attachments.reduce((total, current) => {
      const hasFailed =
        state.attachmentUploads[current.id] &&
        state.attachmentUploads[current.id].state === 'failed';
      if (current.size && !hasFailed) {
        total += parseInt(current.size, 10);
      }
      return total;
    }, 0),
  isUpdatingAttachment: (_state, { allAttachments }) =>
    allAttachments.some(
      (attachment) =>
        attachment.state === 'removing' || attachment.state === 'uploading'
    ),
  sending: (state) => state.sending,
  allKeysAvailable: (state) =>
    Object.values(state.keyState).every((keyAvailable) => keyAvailable),
  allRecipientsDraft: (state) => {
    return [state.draft.to, state.draft.cc, state.draft.bcc]
      .filter((fieldRecipient) => fieldRecipient && fieldRecipient.length)
      .reduce(
        (allRecipients, fieldRecipients) => [
          ...allRecipients,
          ...fieldRecipients,
        ],
        []
      );
  },
  keyNotAvailableRecipients: (state) =>
    Object.keys(state.keyState).filter((key) => !state.keyState[key]),
  // When we haven't done a check yet, or the check fails (and resets to {}), we consider the keyCheck unsuccessful
  keyCheckSuccessful: (state) => Object.keys(state.keyState).length > 0,
};

const actions = {
  checkKeys({ commit }, { sender, addressList }) {
    return api
      .checkKeys({ sender, addressList })
      .then((data) => {
        commit(types.SET_KEY_STATE, {
          keyState: Object.entries(data.addresses).reduce(
            (keyState, [address, { key_available }]) => ({
              ...keyState,
              [address]: key_available,
            }),
            {}
          ),
        });
        return data;
      })
      .catch(() => {
        commit(types.SET_KEY_STATE, { keyState: {} });
        return {};
      });
  },
  async getDraft({ commit }, { id }) {
    const draftMessage = await api.drafts.get({ id });
    commit(types.SET_DRAFT, { draft: draftMessage });
    commit(types.SET_ATTACHMENTS, { attachments: draftMessage.files });
  },
  async loadDraftFromMessage({ commit }, { id, includeAttachments }) {
    const draftMessage = await api.drafts.get({ id });
    draftMessage.id = null;
    commit(types.SET_DRAFT, {
      draft: {
        ...draftMessage,
        files: includeAttachments ? draftMessage.files : [],
      },
    });

    if (includeAttachments) {
      commit(types.SET_ATTACHMENTS, { attachments: draftMessage.files });
    }

    commit(types.SET_DRAFT_FROM_MESSAGE, { messageId: id });
  },
  async saveDraft({ commit, dispatch, getters, state }, { draft }) {
    const serializeRecipient = ({ email, name, contact }) =>
      contact ? { email, name, contact_id: contact.id } : { email, name };
    const replaceInlineImages = (body) => {
      if (!body) {
        return '';
      }

      const fragment = document.createRange().createContextualFragment(body);
      const serializer = new XMLSerializer();
      Array.from(fragment.querySelectorAll('[data-cid]')).forEach((el) => {
        try {
          const cid = el.dataset.cid;
          const img =
            el.tagName.toLowerCase() === 'img' ? el : el.querySelector('img');
          img.src = `cid:${cid}`;
          img.dataset.cid = cid;
          el.parentNode.replaceChild(img, el);
        } catch (err) {
          el.parentNode.removeChild(el);
        }
      });
      return serializer.serializeToString(fragment);
    };

    const serializedDraft = {
      ...draft,
      to: draft.to.map(serializeRecipient),
      cc: draft.cc.map(serializeRecipient),
      bcc: draft.bcc.map(serializeRecipient),
      from: serializeRecipient(draft.from),
      body: replaceInlineImages(draft.body),
    };

    const isNewDraft = !state.draft.id;

    let draftMessage;
    try {
      if (isNewDraft && state.createFromMessage) {
        draftMessage = await api.drafts.createFromMessage({
          id: state.createFromMessage,
          draft: serializedDraft,
        });
      } else if (isNewDraft) {
        draftMessage = await api.drafts.create({ draft: serializedDraft });
      } else {
        draftMessage = await api.drafts.update({
          id: state.draft.id,
          draft: serializedDraft,
        });
      }
    } catch (err) {
      draftMessage = serializedDraft;
      if (state.draft.id) {
        draftMessage.id = state.draft.id;
      }
      throw err;
    }

    if (isNewDraft) {
      dispatch('changeUnreadMessagesCountInFolder', {
        folderId: 'Drafts',
        unreadCount: getters.folderById('Drafts')?.unread_messages_count + 1,
      });
    }

    commit(types.SET_DRAFT, { draft: draftMessage });
    commit(types.SET_ATTACHMENTS, {
      attachments: draftMessage.files ? Array.from(draftMessage.files) : [],
    });

    return draftMessage;
  },
  resetDraft({ commit }) {
    commit(types.SET_DRAFT, { draft: {} });
    commit(types.SET_ATTACHMENTS, { attachments: [] });
    commit(types.SET_DRAFT_FROM_MESSAGE, { messageId: null });
  },
  async sendDraft(
    { commit, dispatch, getters },
    { draftId, passwordProtectedMessage, signPassphrase }
  ) {
    commit(types.SENDING_DRAFT, { sending: true });

    let signing = null;
    if (signPassphrase) {
      signing = { pgpPassphrase: signPassphrase };
    }

    return api.drafts
      .send({ draft_id: draftId, passwordProtectedMessage, signing })
      .then((response) => {
        commit(types.SET_DRAFT, {});
        commit(types.SENDING_DRAFT, { sending: false });
        dispatch('changeUnreadMessagesCountInFolder', {
          folderId: 'Drafts',
          unreadCount: getters.folderById('Drafts')?.unread_messages_count - 1,
        });
        return response;
      })
      .catch((err) => {
        commit(types.SENDING_DRAFT, { sending: false });
        throw err;
      });
  },
  attachFileToDraft({ commit, getters }, { draftId, file, objectUrl }) {
    return new Promise((resolve, reject) => {
      const attachment = {
        id: attachmentId++,
        filename: file.name,
        size: file.size,
        content_type: file.type,
        url: null,
        inline: Boolean(objectUrl),
        objectUrl,
      };

      commit(types.ADD_ATTACHMENT, { attachment });
      commit(types.ADD_ATTACHMENT_PROGRESS, { id: attachment.id });

      if (getters.totalAttachmentsSize > MAX_ATTACHMENTS_SIZE) {
        commit(types.UPDATE_ATTACHMENT_PROGRESS, {
          id: attachment.id,
          updates: { state: 'failed' },
        });
        // Throw the error in the same format as Axios error object so that a global function getErrorCode can be used to extract the error code.
        const err = {
          response: {
            data: {
              code: ATTACHMENT_IS_TOO_BIG,
            },
          },
        };
        reject(err);
      } else {
        api.drafts
          .attachFile({
            draftId,
            file,
            inline: attachment.inline,
            onUploadProgress(event) {
              if (
                event.lengthComputable &&
                state.attachmentUploads[attachment.id]
              ) {
                const progress = event.loaded / event.total;
                commit(types.UPDATE_ATTACHMENT_PROGRESS, {
                  id: attachment.id,
                  updates: { progress },
                });
              }
            },
            onCancelToken(cancelToken) {
              commit(types.UPDATE_ATTACHMENT_PROGRESS, {
                id: attachment.id,
                updates: { cancelToken },
              });
            },
          })
          .then((response) => {
            // skip the update when there is a 204 response from the uploads endpoint after uploading a duplicate file
            if (response) {
              const update = {
                ...response.attached,
                key: attachment.id, //key is used for vue to not lose tracking information
                objectUrl: attachment.objectUrl,
              };
              commit(types.UPDATE_ATTACHMENT, { id: attachment.id, update });
            } else {
              commit(types.REMOVE_ATTACHMENT, { id: attachment.id });
            }
            commit(types.REMOVE_ATTACHMENT_PROGRESS, { id: attachment.id });
            resolve(response);
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
              return;
            }
            commit(types.UPDATE_ATTACHMENT_PROGRESS, {
              id: attachment.id,
              updates: { state: 'failed', cancelToken: null },
            });
            reject(err);
          });
      }
    });
  },
  removeFileFromDraft({ state, commit }, { draftId, id }) {
    if (!draftId) {
      commit(types.REMOVE_ATTACHMENT, { id });
      return Promise.resolve();
    }
    return Promise.resolve(state.attachmentUploads[id])
      .then((attachmentProgress) => {
        commit(types.UPDATE_ATTACHMENT_PROGRESS, {
          id,
          updates: { state: 'removing' },
        });
        if (attachmentProgress && attachmentProgress.state === 'uploading') {
          return attachmentProgress.cancelToken.cancel();
        } else if (!attachmentProgress || attachmentProgress.state === 'done') {
          return api.drafts.deleteFile({ draftId, id });
        }
      })
      .then(() => {
        commit(types.REMOVE_ATTACHMENT, { id });
        commit(types.REMOVE_ATTACHMENT_PROGRESS, { id });
      });
  },
  removeUploadingFilesFromDraft({ dispatch, getters }) {
    const uploadingAttachments = getters.attachments.filter(
      (attachment) => attachment.state === 'uploading'
    );
    uploadingAttachments.forEach(({ id }) => {
      dispatch('removeFileFromDraft', { id });
    });
  },
  deleteRecipient({ commit, state, dispatch }, { deletedRecipient }) {
    commit(types.REMOVE_RECIPIENT, { deletedRecipient });
    dispatch('saveDraft', { draft: state.draft });
  },
};

const mutations = {
  [types.SET_DRAFT](state, { draft }) {
    state.draft = { ...draft };
  },
  [types.SET_DRAFT_FROM_MESSAGE](state, { messageId }) {
    state.createFromMessage = messageId;
  },
  [types.SENDING_DRAFT](state, { sending }) {
    state.sending = sending;
  },
  [types.SET_ATTACHMENTS](state, { attachments }) {
    state.attachments = attachments;
  },

  [types.ADD_ATTACHMENT](state, { attachment }) {
    state.attachments.push(attachment);
  },
  [types.ADD_ATTACHMENT_PROGRESS](state, { id }) {
    state.attachmentUploads = {
      ...state.attachmentUploads,
      [id]: {
        progress: 0,
        cancelId: null,
        state: 'uploading',
      },
    };
  },
  [types.REMOVE_ATTACHMENT](state, { id }) {
    state.attachments = state.attachments.filter(
      (attachment) => attachment.id !== id
    );
  },
  [types.UPDATE_ATTACHMENT](state, { id, update }) {
    state.attachments = state.attachments.map((attachment) =>
      attachment.id === id ? update : attachment
    );
  },
  [types.UPDATE_ATTACHMENT_PROGRESS](state, { id, updates }) {
    state.attachmentUploads = {
      ...state.attachmentUploads,
      [id]: {
        ...state.attachmentUploads[id],
        ...updates,
      },
    };
  },
  [types.REMOVE_ATTACHMENT_PROGRESS](state, { id }) {
    state.attachmentUploads = Object.entries(state.attachmentUploads)
      .filter(([key]) => key !== id)
      .reduce(
        (attachmentUploads, [key, value]) => ({
          ...attachmentUploads,
          [key]: value,
        }),
        {}
      );
  },
  [types.SET_KEY_STATE](state, { keyState }) {
    state.keyState = { ...keyState };
  },
  [types.REMOVE_RECIPIENT](state, { deletedRecipient }) {
    state.draft.to = state.draft.to.filter(
      (recipient) => recipient.email !== deletedRecipient
    );
    state.draft.bcc = state.draft.bcc.filter(
      (recipient) => recipient.email !== deletedRecipient
    );
    state.draft.cc = state.draft.cc.filter(
      (recipient) => recipient.email !== deletedRecipient
    );
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
