import * as types from './toast-message-mutation-types';

const state = {
  toastMessage: null,
};

const getters = {
  toastMessage: (state) => state.toastMessage,
};

const actions = {
  setToastMessage({ commit }, { message, route }) {
    commit(types.SET_TOAST_MESSAGE, { message, route });
  },
  removeToastMessage({ commit }) {
    commit(types.REMOVE_TOAST_MESSAGE);
  },
};

const mutations = {
  [types.SET_TOAST_MESSAGE](state, { message, route }) {
    state.toastMessage = { message, route };
  },
  [types.REMOVE_TOAST_MESSAGE](state) {
    state.toastMessage = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
