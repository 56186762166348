import { hashString } from '@/lib/hashString';

/* Storage keys are hashed. Salt is being used when provided. */

export async function getItem(
  key: string,
  salt = '',
  session = false
): Promise<any> {
  let storage;
  try {
    storage = session ? window.sessionStorage : window.localStorage;
  } catch (SecurityError) {
    // can be disabled by user which leads to error being raised!
    return;
  }
  if (storage === null) return;
  const hashedKey = await hashString(key, salt);

  const item = storage.getItem(`sm-hashed:${hashedKey}`);
  if (item) {
    return JSON.parse(item);
  } else {
    return null;
  }
}

export async function removeItem(
  key: string,
  salt = '',
  session = false
): Promise<void> {
  const storage = session ? window.sessionStorage : window.localStorage;
  if (storage === null) return;
  const hashedKey = await hashString(key, salt);
  storage.removeItem(`sm-hashed:${hashedKey}`);
}

export async function setItem(
  key: string,
  value: any,
  salt = '',
  session = false
): Promise<void> {
  const storage = session ? window.sessionStorage : window.localStorage;
  if (storage === null) return;
  const hashedKey = await hashString(key, salt);
  storage.setItem(`sm-hashed:${hashedKey}`, JSON.stringify(value));
}
