import moment, { Moment } from 'moment';
import Vue from 'vue';

export function calendarFormat(myMoment: Moment, now: Moment) {
  const diff = myMoment.diff(now, 'days', true);
  return diff < -6
    ? myMoment.isSame(now, 'year')
      ? 'lastYear'
      : 'sameElse'
    : diff < -2
      ? 'lastWeek'
      : diff <= -1
        ? 'lastDay'
        : diff < 1
          ? myMoment.isSame(now, 'day')
            ? 'sameDay'
            : 'lastDay'
          : diff < 2
            ? 'nextDay'
            : diff < 7
              ? 'nextWeek'
              : 'sameElse';
}

moment.calendarFormat = calendarFormat;

export const mixin = Vue.extend({
  methods: {
    fromNow(
      this: Vue,
      date: string,
      formats = {
        sameDay: 'HH:mm',
        lastDay: this.$gettext('[Yesterday], HH:mm'),
        lastWeek: this.$gettext('[Last] dddd [at] HH:mm'),
        sameElse: this.$gettext('MMM DD, YYYY [at] HH:mm'),
      }
    ): string {
      const momentInstance = moment(new Date(date));
      momentInstance.locale(this.$language.current);
      return momentInstance.calendar(undefined, formats);
    },
    formatDate(date: string | number, format: string): string {
      const momentInstance = moment(new Date(date));
      momentInstance.locale(this.$language.current);
      return momentInstance.format(format);
    },
    isBeforeToday(dateStamp: string): boolean {
      return moment(dateStamp).isBefore(moment());
    },
  },
});

export function isToday(dateStamp: string): boolean {
  return moment(dateStamp).isSame(moment(), 'day');
}

export function daysDiff(dateStamp: string): number {
  return moment(dateStamp).diff(moment(), 'day');
}

export function isBetween(start: string, end: string): boolean {
  return moment().isBetween(start, end);
}

export function isAfter(dateStamp: string): boolean {
  return moment().isAfter(dateStamp);
}

export function isBefore(dateStamp: string): boolean {
  return moment().isBefore(dateStamp);
}

export function unixTime(): number {
  return moment().unix();
}
