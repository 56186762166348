import api from '@/api';
import { SET_AUTHENTICATION_STATUS_NS } from '@/store/authentication/authentication-mutation-types';
import * as ALL_ENDPOINTS from '@/api/endpoints';
import { BROWSER_METRICS_ENDPOINT } from '@/api/endpoints';
import { FEATURE_BROWSER_METRICS } from '@/lib/featureFlags';

const REPORT_PERIOD_MS = 60 * 1000;
const FALLBACK_PATTERN = new RegExp('^(/api/[a-zA-Z]+).*$');

const ENDPOINT_PROPERTIES = [
  // DNS lookup
  'domainLookupStart',
  'domainLookupEnd',

  // Connection setup
  'connectStart',
  'secureConnectionStart',
  'connectEnd',

  // HTTP request. We're skipping 'workerStart' as we're only checking network requests
  'fetchStart',
  'requestStart',
  'responseStart',
  'responseEnd',

  // Request transfer size
  'transferSize',

  // Total duration
  'duration',
];

const IGNORED_ENDPOINTS = [BROWSER_METRICS_ENDPOINT];

export class BrowserMetricsCollector {
  constructor(store) {
    if (
      !window.performance ||
      typeof window.performance.clearResourceTimings !== 'function'
    ) {
      return;
    }

    this.endpoints = this.prepareEndpoints();
    this.store = store;
    this.timer = null;
    this.handleAuthenticationChange();
  }

  /**
   * Returns whether or not metrics should be sent. Will check for the feature flag 'browser_metrics' to be set
   *
   * @returns {boolean}
   */
  shouldSendMetrics() {
    const flag = this.store.getters['features/featureById'](
      FEATURE_BROWSER_METRICS
    );
    return typeof flag !== 'undefined' && flag.enabled;
  }

  /**
   * Prepares a length-sorted list of endpoints we're sending messages to. We're truncating all urls to these
   * endpoints
   *
   * @returns a length-sorted list of endpoints
   */
  prepareEndpoints() {
    return Object.values(ALL_ENDPOINTS)
      .filter((val) => typeof val === 'string')
      .sort((a, b) => b.length - a.length);
  }

  /**
   * Manage the timer lifecycle based on login state. Stops the timer on logout, starts the timer on login
   */
  handleAuthenticationChange() {
    this.store.subscribe(({ type, payload }) => {
      if (type === SET_AUTHENTICATION_STATUS_NS) {
        const { isAuthenticated } = payload;

        if (isAuthenticated) {
          this.timer = setInterval(
            this.reportMetrics.bind(this),
            REPORT_PERIOD_MS
          );
        } else {
          if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
          }
        }
      }
    });
  }

  /**
   * Reports the actual metrics to our collector endpoint
   */
  reportMetrics() {
    const performance = window.performance;

    try {
      if (!this.shouldSendMetrics()) {
        return;
      }

      const resourceTimings = performance.getEntriesByType('resource');
      const resourcesToSend = [];

      resourceTimings.forEach((timing) => {
        if (timing.initiatorType === 'xmlhttprequest') {
          const endpoint = this.determineEndpoint(timing.name);

          if (endpoint && IGNORED_ENDPOINTS.indexOf(endpoint) === -1) {
            resourcesToSend.push(this.createResourceTiming(endpoint, timing));
          }
        }
      }, this);

      if (resourcesToSend.length > 0) {
        api.browsermetrics.post({
          frontend_version: this.store.getters['version'],
          resources: resourcesToSend,
        });
      }
    } finally {
      performance.clearResourceTimings();
    }
  }

  /**
   * Takes a full url, and truncates it to the relative endpoint
   *
   * @param full_url
   * @returns {null|*} the truncated url, or null if it could not be determined
   */
  determineEndpoint(full_url) {
    const url = full_url.substr(full_url.indexOf('/', 'https://'.length));

    for (let i = 0; i <= this.endpoints.length; i++) {
      const endpoint = this.endpoints[i];
      if (url.startsWith(endpoint)) {
        return endpoint;
      }
    }

    const matcher = url.match(FALLBACK_PATTERN);
    if (matcher && matcher.length === 2) {
      return matcher[1];
    }

    return null;
  }

  /**
   * Takes a resource timing endpoint, and extracts the required properties into a resource entry
   * we can send to our collection endpoint
   *
   * @param endpoint this timing info represents
   * @param timing the timing data
   * @returns {{endpoint: *, properties}}
   */
  createResourceTiming(endpoint, timing) {
    const properties = {};

    ENDPOINT_PROPERTIES.forEach((prop) => {
      properties[prop] = timing[prop];
    });

    return {
      endpoint: endpoint,
      properties: properties,
    };
  }
}

export default function createBrowserMetricsCollectorPlugin() {
  return (store) => {
    // fire & forget
    new BrowserMetricsCollector(store);
  };
}
