import Vue from 'vue';
import { Module } from 'vuex';

import {
  ENTITIES_BEFOREUNLOAD_SET,
  ENTITIES_BEFOREUNLOAD_REMOVE,
  ENTITIES_BEFOREUNLOAD_CLEAR_ALL,
} from './entities-mutation-types';

type BeforeunloadFunction = (e: BeforeUnloadEvent) => void;

interface UnloadFunctionObject {
  [id: string]: BeforeunloadFunction;
}

interface BeforeunloadFunctionState {
  beforeunloadFunctions: UnloadFunctionObject;
}

const beforeUnloadModule: Module<BeforeunloadFunctionState, any> = {
  state: {
    beforeunloadFunctions: {},
  },

  getters: {
    beforeunloadFunctionById:
      (state) =>
      (id: string): BeforeunloadFunction | undefined =>
        state.beforeunloadFunctions[id],
    allbeforeunloadFunctions: (state) =>
      Object.values(state.beforeunloadFunctions),
  },

  mutations: {
    [ENTITIES_BEFOREUNLOAD_SET](state, unloadFunction: UnloadFunctionObject) {
      state.beforeunloadFunctions = {
        ...unloadFunction,
        ...state.beforeunloadFunctions,
      };
    },
    [ENTITIES_BEFOREUNLOAD_REMOVE](state, id: string) {
      Vue.delete(state.beforeunloadFunctions, id);
    },
    [ENTITIES_BEFOREUNLOAD_CLEAR_ALL](state) {
      state.beforeunloadFunctions = {};
    },
  },

  actions: {
    unloadAndRemoveAllFunctions({ state, commit }) {
      Object.values(state.beforeunloadFunctions).forEach(
        (beforeunloadFunction) => {
          window.removeEventListener('beforeunload', beforeunloadFunction);
        }
      );
      commit(ENTITIES_BEFOREUNLOAD_CLEAR_ALL);
    },
    removeBeforeunloadFunction({ commit }, id) {
      commit(ENTITIES_BEFOREUNLOAD_REMOVE, id);
    },
    addBeforeunloadFunction(
      { commit },
      beforeUnloadObject: UnloadFunctionObject
    ) {
      commit(ENTITIES_BEFOREUNLOAD_SET, beforeUnloadObject);
    },
  },
};

export default beforeUnloadModule;
