import beforeunload from './beforeunload';
import features from './features';
import folders from './folders';
import imapTokens from './imap-tokens';
import messages from './messages';
import keys from './keys';
import quota from './quota';

export default {
  modules: {
    beforeunload,
    features,
    folders,
    imapTokens,
    messages,
    keys,
    quota,
  },
};
