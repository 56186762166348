
  import api from '@/api';
  import BaseMeter from '@/components/BaseMeter.vue';
  import Icon from '@/components/Icon/Icon.vue';
  import InputField from '@/components/form/InputField.vue';
  import NotificationBlock from '@/components/NotificationBlock/NotificationBlock.vue';
  import debounce from 'debounce';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'PasswordField',
    components: {
      BaseMeter,
      Icon,
      InputField,
      NotificationBlock,
    },
    props: {
      focused: {
        type: Boolean,
        required: false,
        default: true,
      },
      id: {
        type: String,
        required: true,
      },
      labelText: {
        type: String,
        default: '',
      },
      showCustomValidity: {
        type: Boolean,
        default: false,
      },
      showPasswordStrength: {
        type: Boolean,
        default: false,
      },
      showPasswordStrengthMessage: {
        type: Boolean,
        default: true,
      },
      userInputs: {
        type: Array,
        default: () => [],
      },
      validationErrorMessage: {
        type: String,
        default: '',
      },
      validateInput: {
        type: Boolean,
        required: false,
      },
      value: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        strongPassword: false,
        focusPasswordField: false,
        maskPassword: true,
        passwordScore: 0,
        isValidated: false,
        passwordStrengthMeasured: false,
      };
    },
    computed: {
      password: {
        get(): string {
          return this.value;
        },
        set(val: string) {
          this.$emit('input', val);
        },
      },
      passwordFieldType(): string {
        return this.maskPassword ? 'password' : 'text';
      },
      passwordText(): string {
        return this.$gettext('Password');
      },
      showPasswordText(): string {
        return this.$gettext('Show password');
      },
      hidePasswordText(): string {
        return this.$gettext('Hide password');
      },
      passwordStrengthClassName(): string {
        switch (this.passwordScore) {
          case 4:
            return 'score-strong';
          case 3:
            return 'score-good';
          default:
            return 'score-weak';
        }
      },
      passwordStrength(): string {
        switch (this.passwordScore) {
          case 4:
            return this.$gettext('Strong password');
          case 3:
            return this.$gettext('Good password');
          default:
            return this.$gettext('Weak password');
        }
      },
      passwordValidationText(): string {
        return (
          this.validationErrorMessage || this.$gettext('Password is required.')
        );
      },
    },
    watch: {
      password(newPwd: string) {
        if (this.showPasswordStrength) {
          if (newPwd === '') {
            (this as any).debouncedMeasurePasswordStrength.clear();
            this.passwordStrengthMeasured = false;
            this.passwordScore = 0;
            this.$emit('scoreChange', {
              passwordStrengthMeasured: this.passwordStrengthMeasured,
              passwordScore: this.passwordScore,
            });
          } else {
            (this as any).debouncedMeasurePasswordStrength();
          }
        }
      },
    },
    created() {
      (this as any).debouncedMeasurePasswordStrength = debounce(async function (
        this: any
      ) {
        await this.measurePasswordStrength();
      }, 200);
    },
    methods: {
      async measurePasswordStrength(this: any) {
        this.debouncedMeasurePasswordStrength.clear();
        const response = await api.authentication.passwordStrength({
          password: this.password,
          user_inputs: this.userInputs.map(String),
        });
        this.focusPasswordField = true;
        this.passwordScore = response.score;
        this.passwordStrengthMeasured = true;
        this.$emit('scoreChange', {
          passwordStrengthMeasured: this.passwordStrengthMeasured,
          passwordScore: response.score,
        });
      },
      focus() {
        (this.$refs.password as any).focus();
      },
      eyeButtonClicked() {
        this.maskPassword = !this.maskPassword;
        this.focus();
      },
    },
  });
