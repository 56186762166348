import { Module } from 'vuex';
import api from '@/api';

export const ENTITIES_QUOTA_SET = 'ENTITIES:QUOTA:SET';

interface QuotaState {
  storage_used: number | null;
  storage_total: number | null;
}

const quotaStoreModule: Module<QuotaState, any> = {
  namespaced: true,
  state: {
    storage_used: null,
    storage_total: null,
  },
  getters: {
    storagePercentageUsed: (state) =>
      state.storage_total
        ? state.storage_used && (100 * state.storage_used) / state.storage_total
        : 0,
    storageFull: (state, getter) => getter.storagePercentageUsed >= 100,
    storageAlmostFull: (state, getter) =>
      getter.storagePercentageUsed >= 95 && !getter.storageFull,
  },
  actions: {
    getQuotaInformation({ commit }) {
      return api.quota.get().then(({ storage_used, storage_total }) => {
        commit(ENTITIES_QUOTA_SET, { storage_used, storage_total });
      });
    },
  },
  mutations: {
    [ENTITIES_QUOTA_SET](state, { storage_used, storage_total }) {
      state.storage_used = storage_used;
      state.storage_total = storage_total;
    },
  },
};

export default quotaStoreModule;
