import * as types from './sidebar-mutation-types';
import * as localStorage from '@/lib/localStorage';

const state = {
  sidebarIsOpen: true,
  sidebarDesktopState: true,
  isMobileMenu: false,
};

const getters = {
  sidebarIsOpen: (state) => state.sidebarIsOpen,
  isMobileMenu: (state) => state.isMobileMenu,
};

const actions = {
  async loadSidebarStatus({ commit }) {
    let sidebarStatus = await localStorage.getItem('sidebar-is-open');
    if (sidebarStatus !== null) {
      sidebarStatus = sidebarStatus === 'true';
      commit(types.INIT_SIDEBAR_STATUS, { sidebarStatus });
    } else {
      // set default.
      commit(types.INIT_SIDEBAR_STATUS, {
        sidebarStatus: state.sidebarDesktopState,
      });
    }
  },
  toggleSidebar({ commit }) {
    commit(types.TOGGLE_SIDEBAR);
  },
  setMobileMenu({ commit }, value) {
    commit(types.SET_MOBILE_MENU, value);
  },
  closeMobileSidebar({ state, commit }) {
    if (state.sidebarIsOpen && state.isMobileMenu) {
      commit(types.TOGGLE_SIDEBAR);
    }
  },
};

const mutations = {
  [types.INIT_SIDEBAR_STATUS](state, { sidebarStatus }) {
    state.sidebarIsOpen = sidebarStatus;
    state.sidebarDesktopState = state.sidebarIsOpen;
  },
  [types.TOGGLE_SIDEBAR](state) {
    if (state.isMobileMenu) {
      state.sidebarIsOpen = !state.sidebarIsOpen;
      return;
    }
    state.sidebarIsOpen = !state.sidebarIsOpen;
    state.sidebarDesktopState = state.sidebarIsOpen;
    localStorage.setItem(
      'sidebar-is-open',
      JSON.stringify(state.sidebarIsOpen)
    );
  },
  [types.SET_MOBILE_MENU](state, value) {
    state.isMobileMenu = value;
    // on mobile view, we always collapse the sidebar but we restore it
    // into its previous state on exit of the mobile view
    if (state.isMobileMenu) {
      state.sidebarIsOpen = false;
    } else {
      state.sidebarIsOpen = state.sidebarDesktopState;
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
