/* eslint-disable no-console */

import Vue from 'vue';
import * as Sentry from '@sentry/vue';

export const logWarning = (message: string) => {
  console.warn(message);
  Sentry.captureMessage(message, 'warning');
};

export const logError = (message: string) => {
  console.error(message);
  Sentry.captureMessage(message, 'error');
};

export const logException = (message: string) => {
  console.error(message);
  Sentry.captureException(message);
};

export default Vue.extend({
  methods: {
    logWarning,
    logError,
    logException,
  },
});
