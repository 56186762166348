export async function hashString(text: string, salt: string): Promise<string> {
  const stringToHash = `${text}${salt}`;
  let resultBuffer;

  if (window.crypto && 'subtle' in window.crypto) {
    // feature detect crypto.subtle
    resultBuffer = await crypto.subtle.digest(
      'SHA-256',
      new TextEncoder().encode(stringToHash)
    );
  } else {
    const { Sha256 } = await import(
      /* webpackChunkName: "@aws-crypto/sha256-js" */ '@aws-crypto/sha256-js'
    );
    const hash = new Sha256();
    hash.update(stringToHash);
    resultBuffer = await hash.digest();
  }

  // Kindly borrowed from https://jameshfisher.com/2017/10/30/web-cryptography-api-hello-world/
  return Array.prototype.map
    .call(new Uint8Array(resultBuffer), (x) =>
      ('00' + x.toString(16)).slice(-2)
    )
    .join('');
}
