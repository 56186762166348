export const DELETED_USER = 'DeletedUserConfirmation';
export const LOGIN = 'Login';
export const SIGNUP_DEFAULT = 'SignupDefault';
export const SIGNUP_CUSTOM_DOMAIN = 'SignupCustomDomain';
export const SIGNUP_WITH_COUPON = 'SignupWithCoupon';
export const SIGNUP_SUPPORT = 'SignupSupport';
export const SIGNUP_FEEDBACK = 'SignupFeedback';
export const SIGNUP_USERNAME_ERROR = 'SignupUsernameError';
export const RECOVERY_EMAIL_CONFIRMATION = 'RecoveryEmailConfirmation';
export const RECOVERY_EMAIL_CONFIRMATION_OLD = 'RecoveryEmailConfirmationOld';
export const PASSWORD_RECOVERY_INITIATE = 'PasswordRecoveryInitiate';
export const PASSWORD_RECOVERY_CONFIRMATION = 'PasswordRecoveryConfirmation';
export const PASSWORD_RECOVERY_NEW_RECOVERY_CODE =
  'PasswordRecoveryNewRecoveryCode';
export const PASSWORD_RECOVERY_SET_NEW_PASSWORD =
  'PasswordRecoverySetNewPassword';
export const MAIL = 'Mail';
export const MAIL_COMPOSE = 'Compose';
export const MAIL_SEARCH = 'SearchMail';
export const MAIL_FOLDER = 'FolderView';
export const MAIL_MESSAGE = 'Message';
export const CONTACTS = 'Contacts';
export const CONTACTS_GROUP = 'ContactGroupView';
export const CONTACTS_CONTACT = 'Contact';
export const CONTACTS_SEARCH = 'SearchContacts';
export const SETTINGS = 'Settings';
export const SETTINGS_ACCOUNT = 'AccountSettings';
export const SETTINGS_DOMAINS = 'Domains';
export const SETTINGS_MAIL = 'MailSettingsGeneral';
export const SETTINGS_MAIL_FOLDERS = 'FolderManagement';
export const SETTINGS_ENCRYPTION = 'EncryptionSettings';
export const SETTINGS_PGP_KEYRING = 'KeyManagement';
export const SETTINGS_PGP_KEYRING_SEARCH = 'SearchKeys';
export const SETTINGS_PGP_KEYRING_KEY = 'Key';
export const SETTINGS_MAIL_FILTERS = 'FiltersSettings';
export const SETTINGS_CONTACT_CONTACTGROUPS = 'ContactGroupManagement';
export const SETTINGS_IMAP_TOKENS = 'ImapTokens';
export const SETTINGS_SUBSCRIPTION = 'Subscription';
export const SETTINGS_TUTORIALS = 'Tutorials';
export const SUBSCRIPTION_EXPIRED = 'SubscriptionExpired';
export const COOKIES_NOT_ENABLED = 'CookiesNotEnabled';
export const SETTINGS_EMAIL_MIGRATION = 'EmailMigration';
export const SETTINGS_GROUP_SUBSCRIPTIONS = 'GroupSubscriptions';

export const ALIASES = 'Aliases';
export const ALIASES_BURNER = 'Aliases/Burner';
export const ALIASES_DOMAIN = 'Aliases/Domain';
export const ALIASES_PERSONAL = 'Aliases/Personal';
