export const SET_SELECTED_MESSAGES = 'MAIL:MESSAGES:SET_SELECTED_MESSAGES';
export const SET_ACTIVE_MESSAGE = 'MAIL:MESSAGES:SET_ACTIVE_MESSAGE';
export const SET_ACTIVE_MESSAGES_IDS = 'MAIL:MESSAGES:SET_ACTIVE_MESSAGES_IDS';
export const SET_LOADING_MESSAGES = 'MAIL:MESSAGES:SET_LOADING_MESSAGES';

const state = {
  activeMessageId: null, // Id of the message opened in the message view.
  selectedMessagesIds: [], // Ids of the messages selected in the message list.
  activeMessagesIds: [], // Ids of messages listed in the message list.
  loadingMessages: false,
};

const getters = {
  activeMessageId: (state) => state.activeMessageId,
  activeMessage: (state, getters) => getters.messageById(state.activeMessageId),
  activeMessageIndex: (state) =>
    state.activeMessagesIds.findIndex((id) => id === state.activeMessageId),
  activeMessagesCount: (state) => state.activeMessagesIds.length,
  nextMessageId: (state, getters) =>
    state.activeMessagesIds[getters.activeMessageIndex + 1],
  previousMessageId: (state, getters) =>
    state.activeMessagesIds[getters.activeMessageIndex - 1],
  selectedMessages: (state, getters) =>
    state.selectedMessagesIds.map(getters.messageById),
  selectedMessagesLength: (state) => state.selectedMessagesIds.length,
  loadingMessages: (state) => state.loadingMessages,
};

const actions = {
  clearActiveMessage({ commit }) {
    commit(SET_ACTIVE_MESSAGE, { id: null });
  },
  // Sets the list of active messages. To ensure consistency in the message list,
  // the message selection is updated to only include active messages.
  setActiveMessages({ commit, state }, { ids }) {
    // Update selection to only contain active messages.
    const prunedSelectedMessagesIds = state.selectedMessagesIds.filter((id) =>
      ids.includes(id)
    );
    if (prunedSelectedMessagesIds.length !== state.selectedMessagesIds.length) {
      commit(SET_SELECTED_MESSAGES, { ids: prunedSelectedMessagesIds });
    }

    // Update list of active messages.
    commit(SET_ACTIVE_MESSAGES_IDS, { ids });
  },
  selectMessage({ commit, state }, { id }) {
    if (
      state.activeMessagesIds.includes(id) &&
      !state.selectedMessagesIds.includes(id)
    ) {
      commit(SET_SELECTED_MESSAGES, {
        ids: [...state.selectedMessagesIds, id],
      });
    }
  },
  deselectMessage({ commit, state }, { id }) {
    commit(SET_SELECTED_MESSAGES, {
      ids: state.selectedMessagesIds.filter((selectedId) => selectedId !== id),
    });
  },
  setMessageSelection({ commit }, { ids }) {
    commit(SET_SELECTED_MESSAGES, {
      ids: ids.filter((id) => state.activeMessagesIds.includes(id)),
    });
  },
  deselectAllMessages({ commit }) {
    commit(SET_SELECTED_MESSAGES, { ids: [] });
  },
  selectAllMessages({ commit, state }) {
    commit(SET_SELECTED_MESSAGES, { ids: state.activeMessagesIds });
  },
  setLoadingMessages({ commit }, loadingMessages) {
    commit(SET_LOADING_MESSAGES, { loadingMessages });
  },
};

const mutations = {
  [SET_ACTIVE_MESSAGE](state, { id }) {
    state.activeMessageId = id;
  },
  [SET_ACTIVE_MESSAGES_IDS](state, { ids }) {
    state.activeMessagesIds = ids;
  },
  [SET_SELECTED_MESSAGES](state, { ids }) {
    state.selectedMessagesIds = ids;
  },
  [SET_LOADING_MESSAGES](state, { loadingMessages }) {
    state.loadingMessages = loadingMessages;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
