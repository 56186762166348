import Vue from 'vue';

export default Vue.extend({
  methods: {
    getFolderName(folder: Folder) {
      if (!folder) return '';
      if (!folder.parent_id) {
        switch (folder.type) {
          case 'inbox':
            return this.$gettext('Inbox');
          case 'flagged':
            return this.$gettext('Flagged');
          case 'drafts':
            return this.$gettext('Drafts');
          case 'sent':
            return this.$gettext('Sent');
          case 'trash':
            return this.$gettext('Trash');
          case 'junk':
            return this.$gettext('Spam');
        }
      }
      return folder.display_name;
    },
    getIcon(folder: Folder) {
      if (!folder.parent_id) {
        switch (folder.type) {
          case 'inbox':
            return 'inbox';
          case 'flagged':
            return 'flag';
          case 'archive':
            return 'archive';
          case 'drafts':
            return 'draft';
          case 'sent':
            return 'send';
          case 'trash':
            return 'trash';
          case 'junk':
            return 'spam';
        }
      }
      return 'folder';
    },
    isRootFolder(folder: Folder) {
      return folder.parent_id === null;
    },
  },
});
