import api, { AliasType } from '@/api';
import { Module } from 'vuex';

const LOAD_ALIASES = 'LOAD_ALIASES';
const REPLACE_BURNER = 'REPLACE_BURNER';

interface AliasesState {
  aliases: Alias[];
  customDomains: Domain[];
  startmailDomains: string[];
}

const AliasesStoreModule: Module<AliasesState, any> = {
  namespaced: true,
  state: {
    aliases: [],
    customDomains: [],
    startmailDomains: [],
  },
  getters: {
    burnerAlias: (state): Alias | undefined =>
      state.aliases.find((alias) => alias.type === 'burner'),
    personalAliases: (state): Alias[] =>
      state.aliases.filter((alias) => alias.type === 'personal'),
    domainAliases: (state): Alias[] =>
      state.aliases.filter(
        (alias) => alias.type === 'group' && !alias.alias.startsWith('*@')
      ),
    allowedAliasDomainNamesForType:
      (state) =>
      (aliasType: AliasType): string[] => {
        return aliasType === 'personal'
          ? state.startmailDomains
          : state.customDomains
              .filter((d) => d.verified)
              .map((d) => d.domain_name);
      },
    senderAliases: (state, _getters, rootState): Alias[] =>
      [
        {
          alias: rootState.authentication.user.email as string,
          created: '',
          editable: false,
          id: '',
          type: 'personal',
          enabled: true,
          expires: null,
          displayName: rootState.authentication.user.preferences
            .display_name as string,
          note: '',
          recipients: [rootState.authentication.user.email as string],
        },
        ...state.aliases
          .filter(
            (alias) =>
              alias.enabled &&
              (!alias.expires ||
                Date.parse(alias.expires) > new Date().getTime()) &&
              alias.type !== 'burner' &&
              !alias.alias.startsWith('*@')
          )
          .sort((a, b) => a.alias.localeCompare(b.alias))
          .sort((a, b) => (b.displayName ? 1 : 0) - (a.displayName ? 1 : 0)),
      ].filter((alias) =>
        alias.recipients.includes(rootState.authentication.user.email as string)
      ),
    findSenderAlias: (_state, getters) => (addresses: string[]) => {
      // Return the best-matching sender based on the ordered list of input
      // addresses.
      for (const address of addresses) {
        for (const senderAlias of getters.senderAliases) {
          if (address.toLowerCase() === senderAlias.alias.toLowerCase()) {
            return senderAlias;
          }
        }
      }
    },
    myAddresses: (state, _getters, rootState): string[] => [
      // these are all addresses, active or not, that are ours
      rootState.authentication.user.email,
      ...state.aliases
        .filter((alias) => !alias.alias.startsWith('*@'))
        .map((alias) => alias.alias.toLowerCase()),
    ],
    catchall:
      (state) =>
      (domain: string): Alias | undefined =>
        state.aliases.find((alias) => alias.alias === `*@${domain}`),
  },
  actions: {
    async loadAliases({ commit, dispatch }) {
      const { aliases, alias_domains: startmailDomains } =
        await api.aliases.get();
      const customDomains = await dispatch('authentication/getDomains', null, {
        root: true,
      });
      commit(LOAD_ALIASES, {
        aliases,
        customDomains,
        startmailDomains,
      });
    },
    async createBurner({ commit }) {
      const burner = await api.aliases.createBurner();
      api.uiEvents.create({ event_type: 'alias_created_burner' });
      commit(REPLACE_BURNER, burner);
    },
    async activate({ dispatch }, { id, active }) {
      // Note: manual (de)activation deliberately clears the expiry
      // field. From a user perspective expiry is just ‘automatic
      // deactivation at a later point in time’.
      await api.aliases.update({
        id,
        update: { enabled: active, expires: null },
      });
      dispatch('loadAliases');
    },
    async remove({ dispatch }, id) {
      await api.aliases.delete(id);
      dispatch('loadAliases');
    },
    async enableCatchAll({ dispatch, rootState }, domainName: string) {
      const alias: CreateAliasRequest = {
        alias: `*@${domainName}`,
        display_name: '',
        expires: null,
        note: '',
        recipients: [rootState.authentication.user.email],
        type: 'group',
      };
      await api.aliases.create(alias);
      await dispatch('loadAliases');
    },
    async disableCatchAll({ dispatch, state }, domainName: string) {
      const catchAll = state.aliases.find(
        (alias) => alias.alias === `*@${domainName}`
      );
      if (catchAll) {
        await api.aliases.delete(catchAll.id);
        await dispatch('loadAliases');
      }
    },
  },
  mutations: {
    [LOAD_ALIASES]: (state, { aliases, customDomains, startmailDomains }) => {
      state.aliases = aliases;
      state.customDomains = [...customDomains].sort((d1: Domain, d2: Domain) =>
        d1.domain_name.localeCompare(d2.domain_name)
      );
      state.startmailDomains = [...startmailDomains].sort();
    },
    [REPLACE_BURNER]: (state, newBurner) => {
      const oldBurnerIndex = state.aliases.findIndex(
        (alias) => alias.type === 'burner'
      );
      if (oldBurnerIndex >= 0) {
        state.aliases.splice(oldBurnerIndex, 1);
      }
      state.aliases.push(newBurner);
    },
  },
};

export default AliasesStoreModule;
