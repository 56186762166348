/**
 * Given all the items, the selected items and the to be selected/ unselected item.
 * We return the new selected items by executing the following steps:
 *
 * - Get the index of the to-be-selected item
 * - Get the index of the last selected item (last item in the selected array)
 * - Select/ Deselect all items within the range
 *
 * @param {Array} allItems
 * @param {Array} selectedItems
 * @param {Object} itemToSelect
 * @param {Boolean} newSelectedState
 */
export default function selectItemRange<T>(
  allItems: T[],
  selectedItems: T[],
  itemToSelect: T,
  newSelectedState: boolean
): T[] {
  //we can take some shortcuts if there are no selectedItems
  if (!selectedItems.length) {
    if (newSelectedState) {
      return [itemToSelect];
    } else {
      return [];
    }
  }

  //create a lookup table so we easily lookup the index of an item
  const indexesMap = allItems.reduce((indexesMap, item, index) => {
    indexesMap.set(JSON.stringify(item), index);
    return indexesMap;
  }, new Map());

  const itemIndex = indexesMap.get(JSON.stringify(itemToSelect));
  const lastSelectedItem = selectedItems[selectedItems.length - 1];
  const lastSelectedItemIndex = indexesMap.get(
    JSON.stringify(lastSelectedItem)
  );

  const startIndex = Math.min(lastSelectedItemIndex, itemIndex);
  const endIndex = Math.max(lastSelectedItemIndex, itemIndex);

  //do not deselect any out of range items
  if (
    !newSelectedState &&
    (itemIndex < selectedItems[0] || itemIndex > selectedItems.length - 1)
  ) {
    return [...selectedItems];
  }

  return [
    ...selectedItems.filter(
      (item) => indexesMap.get(JSON.stringify(item)) < startIndex
    ),
    ...(newSelectedState ? allItems.slice(startIndex, endIndex + 1) : []),
    ...selectedItems.filter(
      (item) => indexesMap.get(JSON.stringify(item)) > endIndex
    ),
  ];
}
