import { SET_SELECTED_MESSAGES } from '@/store/mail/modules/messages';
import { SET_PAGE, SET_TOTAL } from '@/store/pagination';
import { MAIL_FOLDERS_SET_ACTIVE_FOLDER } from '../mail/modules/folders';
import { RESET_SORT_ORDER } from '../sorting/sorting-mutation-types';
import * as types from './search-mutation-types';

export default {
  state: {
    activeSearchQuery: null,
    searchToggled: false,
  },

  actions: {
    setActiveSearchQuery({ commit, state }, { query }) {
      commit(MAIL_FOLDERS_SET_ACTIVE_FOLDER, { folderId: null });

      if (query !== state.activeSearchQuery) {
        commit(types.SET_ACTIVE_SEARCH_QUERY, { query });
        commit(`pagination/${SET_PAGE}`, 1);
        commit(`pagination/${SET_TOTAL}`, null);
        commit(RESET_SORT_ORDER);
        commit(SET_SELECTED_MESSAGES, { ids: [] });
      }

      return state.activeSearchQuery;
    },
  },

  mutations: {
    [types.SET_ACTIVE_SEARCH_QUERY](state, { query }) {
      state.activeSearchQuery = query;
    },
    [types.SHOW_SEARCH_INPUT](state) {
      state.searchToggled = true;
    },
    [types.HIDE_SEARCH_INPUT](state) {
      state.searchToggled = false;
    },
  },
};
