import Vue, { VueConstructor } from 'vue';
import DraggableDirective from './draggable';
import FocusDirective from './focus';
import TestDirective from './test';
import AppendToDirective from './append-to';

export function configureSmDirectives(vue: VueConstructor<Vue>) {
  vue.directive('draggable', DraggableDirective);
  vue.directive('focus', FocusDirective);
  vue.directive('test', TestDirective);
  vue.directive('appendTo', AppendToDirective);
}
