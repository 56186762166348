export const ENTITIES_BEFOREUNLOAD_SET = 'ENTITIES:BEFOREUNLOAD:SET';
export const ENTITIES_BEFOREUNLOAD_REMOVE = 'ENTITIES:BEFOREUNLOAD:REMOVE';
export const ENTITIES_BEFOREUNLOAD_CLEAR_ALL =
  'ENTITIES:BEFOREUNLOAD:CLEAR_ALL';

export const ENTITIES_FEATURES_SET = 'ENTITIES:FEATURES:SET';
export const ENTITIES_FEATURES_SET_NS = `features/${ENTITIES_FEATURES_SET}`;
export const ENTITIES_FEATURES_LOADED_FEATURES =
  'ENTITIES:FEATURES:LOADED_FEATURES';
export const ENTITIES_FEATURES_LOADED_FEATURES_NS = `features/${ENTITIES_FEATURES_LOADED_FEATURES}`;
