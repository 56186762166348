import { Module } from 'vuex';
import api from '@/api';

interface SubscriptionState {
  currency: string;
  groupSubscription: GroupSubscription | null;
  chargebeePlan: ChargebeePlan | null;
}

const CHARGEBEE_PLAN = 'CHARGEBEE_PLAN';
const GROUP_SUBSCRIPTIONS = 'GROUP_SUBSCRIPTIONS';
const SET_CURRENCY = 'SET_CURRENCY';

const subscriptionModule: Module<SubscriptionState, any> = {
  namespaced: true,
  state: {
    currency: 'USD',
    groupSubscription: null,
    chargebeePlan: null,
  },

  getters: {
    isAccountCreateAllowed: (state) =>
      state.groupSubscription?.create_account.is_allowed,
    accountCreateNotAllowedReasons: (state) =>
      state.groupSubscription?.create_account.reasons || [],
    getManagerAccounts: (state): AccountDetails[] | undefined =>
      state.groupSubscription?.accounts.filter(
        (item) => item['is_manager'] === true
      ),
    getAdditionalAccounts: (state): AccountDetails[] | undefined =>
      state.groupSubscription?.accounts.filter(
        (item) => item['is_manager'] === false
      ),
    hasMultipleAccounts: (state): undefined | boolean =>
      state.groupSubscription
        ? state.groupSubscription.accounts.length > 1
        : undefined,
  },

  mutations: {
    [SET_CURRENCY](state, currency) {
      state.currency = currency;
    },

    [GROUP_SUBSCRIPTIONS](state, groupSubscription) {
      state.groupSubscription = groupSubscription;
    },

    [CHARGEBEE_PLAN](state, chargebeePlan) {
      state.chargebeePlan = chargebeePlan;
    },
  },

  actions: {
    async getUserCurrency({ commit }) {
      const { currency } = await api.chargebee.userCurrency();
      commit(SET_CURRENCY, currency);
    },

    async getGroupSubscriptions({ commit }) {
      const groupSubscriptions =
        await api.groupSubscriptions.getGroupSubscriptions();
      commit(GROUP_SUBSCRIPTIONS, groupSubscriptions);
    },

    async getChargebeePlan({ commit }) {
      const chargebeePlan = await api.chargebee.getChargebeePlan();
      commit(CHARGEBEE_PLAN, chargebeePlan);
    },
  },
};

export default subscriptionModule;
