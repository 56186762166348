import api from '@/api';
import router from '@/router';
import * as types from '../contacts-mutation-types';
import { SET_TOTAL } from '@/store/pagination';
import selectItemRange from '@/lib/selectItemRange';

const state = {
  contacts: [],
  activeContact: null,
  loadingContacts: false,
  loadingContactsError: null,
  selectedContacts: [],
  primaryAddresses: [],
};

function populatePrimaryEmailAddress(contacts) {
  return contacts
    .filter((contact) => contact.email_addresses.length)
    .map((contact) => ({
      email: (
        contact.email_addresses.find((address) => address.is_primary) ||
        contact.email_addresses[0]
      ).value,
      name: contact.name,
    }));
}

const getters = {
  contacts: (state) => state.contacts,
  loadingContacts: (state) => state.loadingContacts,
  loadingContactsError: (state) => state.loadingContactsError,
  activeContact: (state) => state.activeContact,
  selectedContacts: (state) => state.selectedContacts,
  selectedPrimaryEmailAddresses: (getters) =>
    populatePrimaryEmailAddress(getters.selectedContacts),
  allPrimaryAddresses: (state) =>
    state.primaryAddresses.map((primaryEmailAddress) => ({
      email: primaryEmailAddress.email,
      name: primaryEmailAddress.name,
    })),
  selectedContactsIds: (state) =>
    state.selectedContacts.map((contact) => contact.id),
};

function loadContacts({ commit }, apiRequest) {
  commit(types.RESET_LOADING_CONTACTS_ERRORS);
  commit(types.SET_SELECTED_CONTACTS, { contacts: [] });
  commit(types.LOADING_CONTACTS, { loading: true });

  return new Promise((resolve, reject) => {
    apiRequest()
      .then(({ contacts, total, primaryAddresses }) => {
        commit(types.SET_CONTACTS, { contacts });
        commit(`pagination/${SET_TOTAL}`, total);
        commit(types.LOADING_CONTACTS, { loading: false });
        commit(types.SET_PRIMARY_ADDRESSES, { primaryAddresses });
        resolve({ contacts, total });
      })
      .catch((error) => {
        commit(types.SET_LOADING_CONTACTS_ERROR, { error: error });
        commit(types.LOADING_CONTACTS, { loading: false });
        reject(error);
      });
  });
}

const actions = {
  getContactsForGroup({ commit, getters, rootState }, { id, query }) {
    return loadContacts({ commit }, () =>
      api.contacts.find({
        groupId: id,
        offset: getters['pagination/offset'],
        limit: rootState.pagination.pageSize,
        query,
      })
    );
  },
  async getActiveContact({ commit }, { id }) {
    const contact = await api.contacts.get({ id });
    commit(types.SET_ACTIVE_CONTACT, { contact });
  },
  async addContact({ commit }, { contact }) {
    const newContact = await api.contacts.create({ contact });
    commit(types.SET_CONTACT, { contact: newContact });
    commit(types.CHANGE_CONTACT_COUNT, {
      groupIds: ['all', ...newContact.contact_group_ids],
      count: 1,
    });
    return newContact;
  },
  async updateContact({ commit, state }, { id, contact }) {
    const updatedContact = await api.contacts.update({
      id,
      properties: contact,
    });

    if (state.activeContact && state.activeContact.contact_group_ids) {
      const addedToGroupIds = updatedContact.contact_group_ids.filter(
        (id) => !state.activeContact.contact_group_ids.includes(id)
      );
      if (addedToGroupIds.length) {
        commit(types.CHANGE_CONTACT_COUNT, {
          groupIds: addedToGroupIds,
          count: 1,
        });
      }

      const removedFromGroupIds = state.activeContact.contact_group_ids.filter(
        (id) => !updatedContact.contact_group_ids.includes(id)
      );
      if (removedFromGroupIds.length) {
        commit(types.CHANGE_CONTACT_COUNT, {
          groupIds: removedFromGroupIds,
          count: -1,
        });
      }
    }

    commit(types.SET_ACTIVE_CONTACT, { contact: updatedContact });

    return updatedContact;
  },
  async deleteContact({ commit, rootState }, { contact }) {
    await api.contacts.delete({ id: contact.id });
    commit(types.SELECT_CONTACT, { contact, value: false });
    commit(types.REMOVE_CONTACT, { contact });

    let ids = ['all', ...contact.contact_group_ids];
    if (contact.favorite) {
      ids.push('favorites');
    }

    commit(`pagination/${SET_TOTAL}`, rootState.pagination.total - 1);
    commit(types.CHANGE_CONTACT_COUNT, { groupIds: ids, count: -1 });

    return contact;
  },
  deleteSelectedContacts({ dispatch, state }) {
    const deleteActions = state.selectedContacts.map((contact) => {
      return dispatch('deleteContact', { contact });
    });
    return Promise.all(deleteActions).then((contacts) => {
      dispatch('deselectAllContacts');
      return contacts;
    });
  },
  async moveContact({ commit }, { contact, contactGroupId }) {
    const updatedContact = await api.contacts.update({
      id: contact.id,
      properties: {
        contact_group_ids: [
          ...new Set([...contact.contact_group_ids, contactGroupId]),
        ],
      },
    });

    if (!contact.contact_group_ids.includes(contactGroupId)) {
      commit(types.CHANGE_CONTACT_COUNT, {
        groupIds: [contactGroupId],
        count: 1,
      });
    }

    commit(types.UPDATE_CONTACT, { contact, properties: updatedContact });
    return updatedContact;
  },
  moveSelectedContacts({ dispatch, state }, { contactGroupId }) {
    const moveActions = state.selectedContacts.map((contact) => {
      return dispatch('moveContact', { contact, contactGroupId });
    });
    return Promise.all(moveActions).then((contacts) => {
      dispatch('deselectAllContacts');
      return contacts;
    });
  },
  selectContact({ commit }, { contact, value }) {
    commit(types.SELECT_CONTACT, { contact, value });
  },
  expandContactSelection({ commit, getters }, { contact, value }) {
    const contacts = selectItemRange(
      getters.contacts,
      state.selectedContacts,
      contact,
      value
    );
    commit(types.SET_SELECTED_CONTACTS, { contacts });
  },
  selectAllContacts({ commit, getters }) {
    commit(types.SET_SELECTED_CONTACTS, {
      contacts: getters.contacts.slice(0),
    });
  },
  deselectAllContacts({ commit }) {
    commit(types.SET_SELECTED_CONTACTS, { contacts: [] });
  },
  setContactFavoriteStatus({ commit }, { id, favorite }) {
    return api.contacts.setFavoriteStatus({ id, favorite }).then((contact) => {
      commit(types.SET_CONTACT_FAVORITE_STATUS, {
        id: contact.id,
        favorite: contact.favorite,
      });
      commit(types.CHANGE_CONTACT_COUNT, {
        groupIds: ['favorites'],
        count: contact.favorite ? 1 : -1,
      });

      if (router.currentRoute.params.group === 'favorites') {
        if (contact.favorite) {
          commit(types.APPEND_CONTACTS, { contacts: [contact] });
        } else {
          commit(types.REMOVE_CONTACT, { contact });
        }
      }

      return contact;
    });
  },
  toggleSelectedContactsFavoriteStatus({ dispatch, state }) {
    const allContactsFavorited = state.selectedContacts.every(
      (contact) => contact.favorite
    );
    const contactsThatNeedUpdate = state.selectedContacts.filter(
      ({ favorite }) => favorite !== !allContactsFavorited
    );
    const favoriteActions = contactsThatNeedUpdate.map((contact) => {
      const body = { id: contact.id, favorite: !allContactsFavorited };
      return dispatch('setContactFavoriteStatus', body);
    });

    return Promise.all(favoriteActions).then((contacts) => {
      dispatch('deselectAllContacts');
      return contacts;
    });
  },
  importContacts({ commit }, { files }) {
    return api.contacts
      .import({ files })
      .then(({ contact_group: contactGroup }) => {
        commit(types.ADD_CONTACT_GROUP, { contactGroup });
        commit(types.CHANGE_CONTACT_COUNT, {
          groupIds: ['all'],
          count: contactGroup.contact_count,
        });
        return { contactGroup };
      });
  },
};

const mutations = {
  [types.APPEND_CONTACTS](state, { contacts }) {
    state.contacts = [...state.contacts, ...contacts];
  },
  [types.LOADING_CONTACTS](state, { loading }) {
    state.loadingContacts = loading;
  },
  [types.SET_LOADING_CONTACTS_ERROR](state, { error }) {
    state.loadingContactsError = error;
  },
  [types.RESET_LOADING_CONTACTS_ERRORS](state) {
    state.loadingContactsError = null;
  },
  [types.SET_CONTACTS](state, { contacts }) {
    state.contacts = contacts;
  },
  [types.SET_PRIMARY_ADDRESSES](state, { primaryAddresses }) {
    state.primaryAddresses = primaryAddresses;
  },
  [types.SET_CONTACT](state, { contact }) {
    state.contacts.push(contact);
  },
  [types.REMOVE_CONTACT](state, { contact }) {
    const index = state.contacts.findIndex(({ id }) => contact.id === id);
    state.contacts.splice(index, 1);
  },
  [types.UPDATE_CONTACT](state, { contact, properties }) {
    const index = state.contacts.findIndex(({ id }) => contact.id === id);

    if (index > -1) {
      state.contacts.splice(index, 1, {
        ...state.contacts[index],
        ...properties,
        contact_group_ids:
          properties.contact_group_ids ||
          state.contacts[index].contact_group_ids,
      });
    }
  },
  [types.SELECT_CONTACT](state, { contact, value }) {
    if (value) {
      // assure uniqueness by not pushing if already in the array
      if (!state.selectedContacts.some(({ id }) => contact.id === id)) {
        state.selectedContacts.push({
          ...contact,
        });
      }
    } else {
      const index = state.selectedContacts.findIndex(
        ({ id }) => contact.id === id
      );
      state.selectedContacts.splice(index, 1);
    }
  },
  [types.SET_SELECTED_CONTACTS](state, { contacts }) {
    state.selectedContacts = contacts;
  },
  [types.SET_ACTIVE_CONTACT](state, { contact }) {
    state.activeContact = contact;
  },
  [types.SET_CONTACT_FAVORITE_STATUS](state, { id, favorite }) {
    const contact = state.contacts.find((contact) => contact.id === id);
    if (contact) {
      contact.favorite = favorite;
    }

    // mark the active contact as favorite
    if (state.activeContact?.id === id) {
      state.activeContact.favorite = favorite;
    }
  },
  [types.LOADING_CONTACTS](state, { loading }) {
    state.loadingContacts = loading;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
