export const ADD_CONTACT_GROUP = 'ADD_CONTACT_GROUP';
export const APPEND_CONTACTS = 'APPEND_CONTACTS';
export const CHANGE_CONTACT_COUNT = 'CHANGE_CONTACT_COUNT';
export const LOADING_CONTACTS = 'LOADING_CONTACTS';
export const REMOVE_CONTACT = 'REMOVE_CONTACT';
export const REMOVE_CONTACT_GROUP = 'REMOVE_CONTACT_GROUP';
export const RESET_LOADING_CONTACTS_ERRORS = 'RESET_LOADING_CONTACTS_ERRORS';
export const SELECT_CONTACT = 'SELECT_CONTACT';
export const SET_ACTIVE_CONTACT = 'SET_ACTIVE_CONTACT';
export const SET_ACTIVE_CONTACT_GROUP = 'SET_ACTIVE_CONTACT_GROUP';
export const SET_CONTACT = 'SET_CONTACT';
export const SET_CONTACTS = 'SET_CONTACTS';
export const SET_CONTACT_FAVORITE_STATUS = 'SET_CONTACT_FAVORITE_STATUS';
export const SET_CONTACT_GROUPS = 'SET_CONTACT_GROUPS';
export const SET_CONTACT_GROUPS_LOADED = 'SET_CONTACT_GROUPS_LOADED';
export const SET_LOADING_CONTACTS_ERROR = 'SET_LOADING_CONTACTS_ERROR';
export const SET_PRIMARY_ADDRESSES = 'SET_PRIMARY_ADDRESSES';
export const SET_SELECTED_CONTACTS = 'SET_SELECTED_CONTACTS';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const UPDATE_CONTACT_GROUP = 'UPDATE_CONTACT_GROUP';
export const SET_LOADING_CONTACT_GROUPS_ERROR =
  'SET_LOADING_CONTACT_GROUPS_ERROR';
