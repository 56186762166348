import { DirectiveBinding, ObjectDirective, VNode } from 'vue';

const appendToDirective: ObjectDirective<HTMLElement> = {
  inserted(el: HTMLElement, { value: target }: DirectiveBinding<HTMLElement>) {
    if (!(target instanceof Element)) return;
    target.appendChild(el);
  },

  unbind(
    el: HTMLElement,
    _binding: DirectiveBinding<HTMLElement>,
    vnode: VNode
  ) {
    function destroy() {
      el.parentNode?.removeChild(el);
    }

    if (vnode.context && vnode.data && vnode.data.transition) {
      vnode.context.$once('hook:afterLeave', destroy);
      vnode.context.$once('hook:destroyed', () => {
        vnode.context && vnode.context.$off('hook:afterLeave', destroy);
        destroy();
      });
    } else {
      destroy();
    }
  },
};

export default appendToDirective;
