export const IMAP_GENERIC_ERROR = 1;

export const IMAP_FOLDER_EXISTS = 2;
export const IMAP_FOLDER_DOES_NOT_EXIST = 3;
export const IMAP_FOLDER_IS_SPECIAL_USE = 4;
export const IMAP_MESSAGE_FOLDER_ID_INVALID = 7;
export const IMAP_MESSAGE_DOES_NOT_EXIST = 8;

export const API_BODY_TYPE_ERROR = 5;

export const WEB_DB_NO_RESULT_FOUND = 9;
export const WEB_DB_CONTACT_GROUP_ALREADY_EXISTS = 28;

export const SMTP_GENERIC_ERROR = 10;
export const SMTP_NO_VALID_RECIPIENTS = 11;
export const SMTP_INVALID_ADDRESS_SYNTAX = 12;

export const UNSUPPORTED_BULK_OPERATION = 13;

export const ATTACHMENT_DOES_NOT_EXIST = 14;
export const ATTACHMENT_IS_TOO_BIG = 16;

export const PGP_BAD_PASSPHRASE = 17;
export const PGP_NO_PASSPHRASE = 22;
export const PGP_NO_KEYS_AVAILABLE = 24;
export const PGP_KEY_NOT_AVAILABLE = 26;
export const PGP_DATA_CORRUPTED = 27;
export const PGP_RECIPIENT_KEY_UNAVAILABLE = 30;
export const PGP_GENERIC_ERROR = 45;
export const PGP_KEY_NOT_ASCII_ENCODED = 46;
export const PGP_NOT_KEY_DATA = 53;
export const PGP_SENDER_KEY_UNAVAILABLE = 72;
export const PGP_BAD_SIGNATURE = 88;
export const PGP_KEY_IS_SECRET = 89;
export const PGP_KEY_IMPORT_ERROR = 90;
export const PGP_SIGNATURE_EXPIRED = 91;
export const PGP_KEY_EXPIRED = 98;
export const PGP_KEY_REVOKED = 99;
export const PGP_DECRYPTION_FAILED = 104;

export const UNAUTHENTICATED = 18;
export const AUTHENTICATION_FAILURE = 19;
export const TOTP_FAILURE = 20;
export const SESSION_EXPIRED = 21;

export const INVALID_RECOVERY_EMAIL = 66;
export const ALIAS_USED_AS_RECOVERY_EMAIL = 92;
export const NO_SUCH_RECOVERY_INITIATED = 67;
export const RECOVERY_NOT_ALLOWED = 69;
export const INITIATE_RECOVERY_ACCOUNT_DOES_NOT_EXIST = 74;
export const RECOVERY_ACCOUNT_DOES_NOT_EXIST = 75;
export const INVALID_RECOVERY_CODE = 76;
export const RECOVERY_ALREADY_REQUESTED = 80;
export const UNEXPECTED_RESPONSE_FROM_ACCOUNT_SERVER = 39;

export const IMAP_TOKEN_LIMIT_REACHED = 79;
export const IMAP_TOKEN_IDENTIFIER_EXISTS = 131;

export const CONTACT_IMPORT_FILE_TOO_LARGE = 86;

export const CHARGEBEE_WRONG_VALUE = 110; //used unless more specific error is applicable
export const CHARGEBEE_WRONG_VALUE_COUNTRY = 111;
export const CHARGEBEE_WRONG_VALUE_VAT = 112;
export const CHARGEBEE_WRONG_VALUE_ZIP = 113;
export const CHARGEBEE_PAYMENT_FAILED = 125;
export const CHARGEBEE_INVALID_DEAL_CODE = 94;
export const CHARGEBEE_NOT_LINKED = 40;

export const ALIAS_EMAIL_IN_USE = 9003;
export const ALIAS_INVALID_EMAIL = 9007;
export const ALIAS_NAME_EXCEEDS_LIMIT = 9017;
export const ALIAS_NOTE_EXCEEDS_LIMIT = 9016;
