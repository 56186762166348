export const CHANGE_NEW_PAGE = 'CHANGE_NEW_PAGE';
export const SET_PAGE = 'SET_PAGE';
export const SET_TOTAL = 'SET_TOTAL';
export const UPDATE_PAGE = 'UPDATE_PAGE';

let timeoutId = null;

/* page changes are debounced for this long, and applied on the trailing edge, so with 200ms delay */
const DEFAULT_DEBOUNCE_MS = 200;

/* clamp the num between min and max, inclusive */
const clamp = (num, min, max) => Math.max(min, Math.min(num, max));

const state = {
  // Follows newPage after debouncing.
  page: 1,

  // Manipulated by users directly using the pagination controls.
  newPage: 1,

  pageSize: 50,

  /* Total number of items to page through. */
  total: null,
};

const getters = {
  /* The start of the current page, 0-based, for use with API calls. */
  offset: (state) => (state.page - 1) * state.pageSize,

  /* Number of items on the current page, can be lower than pageSize, e.g. when on the last page. */
  currentPageSize: (state, getters) =>
    clamp(state.total - getters.newPageStart + 1, 0, state.pageSize),

  /* Total number of pages. */
  totalPages: (state) => Math.ceil(state.total / state.pageSize),

  /* The "humanized" first item on the page we're currently on. */
  pageStart: (state) =>
    state.total === 0 ? 0 : (state.page - 1) * state.pageSize + 1,

  /* The "humanized" first item on the page we're about to go to. */
  newPageStart: (state) =>
    state.total === 0 ? 0 : (state.newPage - 1) * state.pageSize + 1,

  /* The "humanized" last item on the page we're about to go to. */
  newPageEnd: (state, getters) =>
    getters.newPageStart + getters.currentPageSize - 1,
};

const actions = {
  change({ commit }, { debounce, delta, router }) {
    commit(CHANGE_NEW_PAGE, delta);
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      commit(UPDATE_PAGE);
      /* Rip out message query string parameter so that any currently selected
      messages gets deselected when the page is changed. Note that this is not
      in the right place. Because this code is shared between contacts and
      messages there should not be any messages-specific code here. */
      const query = { ...router.currentRoute.query, page: state.newPage };
      delete query.message;
      router.push({ ...router.currentRoute, query });
    }, debounce);
  },
  next({ dispatch }, { debounce = DEFAULT_DEBOUNCE_MS, router } = {}) {
    return dispatch('change', { debounce, delta: 1, router });
  },
  previous({ dispatch }, { debounce = DEFAULT_DEBOUNCE_MS, router } = {}) {
    return dispatch('change', { debounce, delta: -1, router });
  },
};

const mutations = {
  [CHANGE_NEW_PAGE](state, delta) {
    const totalPages = Math.ceil(state.total / state.pageSize);
    state.newPage = clamp(state.newPage + delta, 1, totalPages);
  },
  [SET_PAGE](state, page) {
    state.newPage = state.page = page;
  },
  [SET_TOTAL](state, total) {
    state.total = total;
  },
  [UPDATE_PAGE](state) {
    state.page = state.newPage;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
