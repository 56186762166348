import * as types from '../mail-mutation-types';

export const FILTER_UNREAD = 'is_unread';
export const FILTER_FLAGGED = 'is_flagged';
export const FILTER_ATTACHMENT = 'has_attachment';
export const FILTER_ENCRYPTED = 'is_encrypted';

export const FILTER_OPTIONS = new Map([
  [FILTER_UNREAD, {}],
  [FILTER_FLAGGED, {}],
  [FILTER_ATTACHMENT, {}],
  [FILTER_ENCRYPTED, {}],
]);

const state = {
  activeFilters: [],
};

const actions = {
  setFilter({ commit, state }, { filter, active }) {
    const filters = active
      ? [...state.activeFilters, filter]
      : state.activeFilters.filter((value) => value !== filter);
    commit(types.SET_ACTIVE_FILTERS, { filters });
    return [...filters];
  },
  setFilters({ commit }, { filters }) {
    commit(types.SET_ACTIVE_FILTERS, { filters });
    return [...filters];
  },
};

const mutations = {
  [types.SET_ACTIVE_FILTERS](state, { filters }) {
    state.activeFilters = filters;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
