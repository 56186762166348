import Vue from 'vue';
import api from '@/api';
import { getItem, setItem } from '@/lib/localStorage';
import * as types from '@/store/settings/settings-mutation-types';

const state = {
  version: null,
  darkMode: localStorage.getItem('dark-mode') === 'true',
  darkModeResetEditor:
    localStorage.getItem('dark-mode-editor-reset') === 'true',
  recentRecipients: null,
};

const getters = {
  version: () => state.version,
};

const actions = {
  async loadLanguage({ dispatch }) {
    const language =
      new window.URLSearchParams(window.location.search).get('lang') ||
      (await getItem('sm-settings-language')) ||
      window.navigator.language ||
      window.navigator.userLanguage;

    if (language !== null) {
      dispatch('updateLanguage', { language });
    }
  },
  loadRecentRecipients({ commit }) {
    return api.recentRecipients.get().then((recipients) => {
      commit(types.SET_RECENT_RECIPIENTS, { recipients });
    });
  },
  async deleteRecentRecipient({ commit }, recipientId) {
    await api.recentRecipients.deleteOne(recipientId);
    commit(types.DELETE_RECIPIENT, recipientId);
  },
  async deleteRecentRecipients({ commit }) {
    await api.recentRecipients.delete();
    commit(types.SET_RECENT_RECIPIENTS, { recipients: [] });
  },
  async updateLanguage(context, { language }) {
    applyLanguage(language);
    await setItem('sm-settings-language', language);
  },
  setLanguage({ dispatch }, { language }) {
    dispatch('updateLanguage', { language });
    dispatch('authentication/updatePreferences', {
      update: { locale: language },
    });
  },
  async setExternalLinkWarning({ dispatch }, { externalLinkWarning }) {
    dispatch('authentication/updatePreferences', {
      update: {
        external_link_warning: externalLinkWarning,
      },
    });
  },
  async setRecipientCountWarning({ dispatch }, { recipientCountWarning }) {
    dispatch('authentication/updatePreferences', {
      update: {
        recipient_count_warning: recipientCountWarning,
      },
    });
  },
  setDarkModeEditorReset({ commit }, darkModeEditorReset) {
    commit(types.SET_DARK_MODE_EDITOR_RESET, darkModeEditorReset);
  },
  setDarkMode({ commit }, darkMode) {
    commit(types.SET_DARK_MODE, darkMode);
  },
};

const mutations = {
  [types.SET_VERSION](state, { version }) {
    state.version = version;
  },

  [types.SET_DARK_MODE](state, darkMode) {
    state.darkMode = darkMode;
    localStorage.setItem('dark-mode', JSON.stringify(darkMode));
  },
  [types.SET_DARK_MODE_EDITOR_RESET](state, darkModeResetEditor) {
    state.darkModeResetEditor = darkModeResetEditor;
    localStorage.setItem(
      'dark-mode-editor-reset',
      JSON.stringify(darkModeResetEditor)
    );
  },
  [types.SET_RECENT_RECIPIENTS](state, { recipients }) {
    state.recentRecipients = [...recipients];
  },
  [types.DELETE_RECIPIENT](state, recipientId) {
    state.recentRecipients = state.recentRecipients.filter(
      (recipient) => recipient.id !== recipientId
    );
  },
};

function applyLanguage(lang) {
  let language = 'en_US';
  if (lang.startsWith('en')) language = 'en_US';
  if (lang.startsWith('de')) language = 'de_DE';

  Vue.config.language = language;
  document.documentElement.lang = language.replace('_', '-');
}

export default {
  state,
  getters,
  actions,
  mutations,
};
