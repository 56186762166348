import { Module } from 'vuex';

import * as types from './signup-mutation-types';

export const SIGNUP_VERIFY_DOMAIN = 0;
export const SIGNUP_ACCOUNT_DETAILS = 1;
export const SIGNUP_PAYMENT = 2;
export const SIGNUP_RECOVERY_DETAILS = 3;

export const COUPON = 'Coupon';
export const CUSTOM_DOMAIN = 'Custom Domain';
export const CUSTOM_DOMAIN_VERIFIER = 'sm-custom-domain-verifier';
export const ONE_WEEK_COOKIE = 7 * 24;

interface SignupState {
  captchaToken?: string | null;
  couponCode?: string | null;
  deal?: string | null;
  formData?: SignupFormData;
  recoveryCode: SignedData | null;
  hostedPageId?: string | null;
  signupFlow: string | null;
  domain: string;
  domainToken?: string | null;
}

const signupStoreModule: Module<SignupState, any> = {
  namespaced: true,
  state: {
    captchaToken: null,
    couponCode: null,
    deal: null,
    formData: {
      displayName: '',
      requestedEmailLocalPart: '',
      requestedEmailDomainPart: '',
      password: '',
    },
    recoveryCode: null,
    hostedPageId: null,
    signupFlow: null,
    domain:
      process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
        ? 'local.startmail.org'
        : 'startmail.com',
    domainToken: null,
  },

  getters: {
    requestedEmail: ({ formData }) => {
      return formData
        ? `${formData.requestedEmailLocalPart}@${formData.requestedEmailDomainPart}`
        : null;
    },
    steps: (state, getter) => {
      switch (state.signupFlow) {
        case COUPON:
          return getter.couponSignupSteps;

        case CUSTOM_DOMAIN:
          return getter.customDomainSignupSteps;

        default:
          return getter.defaultSignupSteps;
      }
    },
    getSignupAccountDetails: (state) => state.formData,
    captchaToken: (state) => state.captchaToken,
    isCustomDomainSignup: (state) => state.signupFlow === CUSTOM_DOMAIN,
    domain: (state) => state.domain,
    defaultSignupSteps: () => [
      SIGNUP_ACCOUNT_DETAILS,
      SIGNUP_PAYMENT,
      SIGNUP_RECOVERY_DETAILS,
    ],
    couponSignupSteps: () => [SIGNUP_ACCOUNT_DETAILS, SIGNUP_RECOVERY_DETAILS],
    customDomainSignupSteps: () => [
      SIGNUP_VERIFY_DOMAIN,
      SIGNUP_ACCOUNT_DETAILS,
      SIGNUP_PAYMENT,
      SIGNUP_RECOVERY_DETAILS,
    ],
  },
  actions: {
    setSignupDetails({ commit }, formData: SignupFormData) {
      commit(types.SET_SIGNUP_DETAILS, formData);
    },
    setSignupDetailsWithCoupon(
      { commit },
      { formData, couponCode }: { formData: SignupFormData; couponCode: string }
    ) {
      commit(types.SET_SIGNUP_DETAILS, formData);
      commit(types.SET_SIGNUP_COUPON_CODE, couponCode);
    },
    setSignupCaptchaToken({ commit }, captchaToken: string | null) {
      commit(types.SET_SIGNUP_CAPTCHA_TOKEN, captchaToken);
    },
    setHostedPageId({ commit }, hostedPageId: string) {
      commit(types.SET_HOSTED_PAGE_ID, hostedPageId);
    },
    setSignupFlow({ commit }, signupFlow: string) {
      commit(types.SET_SIGNUP_FLOW, signupFlow);
    },
    setSignupDomain({ commit }, signupDomain: string) {
      commit(types.SET_SIGNUP_DOMAIN, signupDomain);
    },
    setDomainToken({ commit }, domainToken: string) {
      commit(types.SET_DOMAIN_TOKEN, domainToken);
    },
    unsetSignupDetails({ commit }) {
      commit(types.SET_SIGNUP_DETAILS, {
        displayName: '',
        requestedEmailLocalPart: '',
        requestedEmailDomainPart: '',
        password: '',
      });
      commit(types.SET_DOMAIN_TOKEN, null);
      commit(
        types.SET_SIGNUP_DOMAIN,
        process.env.NODE_ENV === 'development' ||
          process.env.NODE_ENV === 'test'
          ? 'local.startmail.org'
          : 'startmail.com'
      );
    },
  },
  mutations: {
    [types.SET_SIGNUP_DETAILS](state, formData: SignupFormData) {
      state.formData = formData;
    },
    [types.SET_SIGNUP_COUPON_CODE](state, couponCode: string) {
      state.couponCode = couponCode;
    },
    [types.SET_SIGNUP_CAPTCHA_TOKEN](state, captchaToken: string | null) {
      state.captchaToken = captchaToken;
    },
    [types.SET_HOSTED_PAGE_ID](state, hostedPageId: string) {
      state.hostedPageId = hostedPageId;
    },
    [types.SET_SIGNUP_FLOW](state, signupFlow: string) {
      state.signupFlow = signupFlow;
    },
    [types.SET_SIGNUP_DOMAIN](state, domain: string) {
      state.domain = domain;
    },
    [types.SET_DOMAIN_TOKEN](state, domainToken: string) {
      state.domainToken = domainToken;
    },
  },
};

export default signupStoreModule;
