import FolderUtilsMixin from '@/mixins/folder-utils-mixin';
import * as namedRoutes from '@/router/named-routes';

import {
  FEATURE_FILTERS,
  FEATURE_IMAP_TOKENS,
  FEATURE_RECOVERY,
  FEATURE_SIGNUP,
} from '@/lib/featureFlags';

const components = {
  CookiesNotEnabled: () =>
    import(
      /* webpackChunkName: "CookiesNotEnabled" */ '@/pages/CookiesNotEnabled/CookiesNotEnabled'
    ),

  DeletedUserConfirmation: () =>
    import(
      /* webpackChunkName: "DeletedUserConfirmation" */ '@/pages/DeletedUserConfirmation/DeletedUserConfirmation'
    ),
  LoginPage: () =>
    import(/* webpackChunkName: "LoginPage" */ '@/pages/Login/Login'),
  NotFound: () =>
    import(/* webpackChunkName: "NotFound" */ '@/components/NotFound/NotFound'),
  // Signup chunk
  DefaultSignupFlow: () =>
    import(
      /* webpackChunkName: "Signup"   */ '@/pages/Signup/SignupFlows/DefaultSignupFlow'
    ),
  CouponSignupFlow: () =>
    import(
      /* webpackChunkName: "Signup"   */ '@/pages/Signup/SignupFlows/CouponSignupFlow'
    ),
  CustomDomainSignupFlow: () =>
    import(
      /* webpackChunkName: "Signup"   */ '@/pages/Signup/SignupFlows/CustomDomainSignupFlow'
    ),
  SignupUsernameError: () =>
    import(/* webpackChunkName: "Signup"   */ '@/pages/Signup/UsernameError'),
  SignupFeedback: () =>
    import(/* webpackChunkName: "Signup"   */ '@/pages/Signup/Feedback'),
  SignupWithCoupon: () =>
    import(
      /* webpackChunkName: "Signup"  */ '@/components/Signup/SignupWithCoupon/SignupWithCoupon'
    ),
  SignupSupport: () =>
    import(/* webpackChunkName: "Signup" */ '@/pages/Signup/Support'),

  // Recovery chunk
  PasswordRecoveryConfirmation: () =>
    import(
      /* webpackChunkName: "recovery" */ '@/pages/PasswordRecovery/Confirmation/Confirmation'
    ),
  PasswordRecoveryInitiate: () =>
    import(
      /* webpackChunkName: "recovery" */ '@/pages/PasswordRecovery/Initiate/Initiate'
    ),
  PasswordRecoveryNewRecoveryCode: () =>
    import(
      /* webpackChunkName: "recovery" */ '@/pages/PasswordRecovery/NewRecoveryCode/NewRecoveryCode'
    ),
  PasswordRecoverySetNewPassword: () =>
    import(
      /* webpackChunkName: "recovery" */ '@/pages/PasswordRecovery/SetNewPassword/SetNewPassword'
    ),

  // Mail chunk
  MailPage: () => import(/* webpackChunkName: "mail" */ '@/pages/Mail/Mail'),
  MessageDetailHeader: () =>
    import(
      /* webpackChunkName: "mail" */ '@/components/MessageDetailHeader/MessageDetailHeader'
    ),
  Message: () =>
    import(
      /* webpackChunkName: "mail" */ '@/wrappers/MessageWrapper/MessageWrapper'
    ),
  MessageList: () =>
    import(
      /* webpackChunkName: "mail" */ '@/components/MessageList/MessageList'
    ),
  MailHeader: () =>
    import(/* webpackChunkName: "mail" */ '@/components/MailHeader/MailHeader'),

  // Compose chunk
  Compose: () =>
    import(/* webpackChunkName: "compose" */ '@/components/Compose/Compose'),
  ComposeHeader: () =>
    import(
      /* webpackChunkName: "compose" */ '@/components/ComposeHeader/ComposeHeader'
    ),

  // Expired chunk
  SubscriptionExpiredPage: () =>
    import(
      /* webpackChunkName: "expired" */ '@/pages/SubscriptionExpiredPage/SubscriptionExpiredPage'
    ),

  //Contacts chunk
  ContactsPage: () =>
    import(/* webpackChunkName: "contacts" */ '@/pages/Contacts/Contacts'),
  ContactGroupView: () =>
    import(
      /* webpackChunkName: "contacts" */ '@/components/ContactGroupView/ContactGroupView'
    ),
  Contact: () =>
    import(/* webpackChunkName: "contacts" */ '@/components/Contact/Contact'),
  ContactHeader: () =>
    import(
      /* webpackChunkName: "contacts" */ '@/components/ContactHeader/ContactHeader'
    ),
  ContactDetailHeader: () =>
    import(
      /* webpackChunkName: "contacts" */ '@/components/ContactDetailHeader/ContactDetailHeader'
    ),

  //Settings chunk
  SettingsPage: () =>
    import(/* webpackChunkName: "settings" */ '@/pages/Settings/Settings'),
  SettingsHeader: () =>
    import(
      /* webpackChunkName: "settings" */ '@/components/SettingsHeader/SettingsHeader'
    ),
  MailSettingsGeneral: () =>
    import(
      /* webpackChunkName: "settings" */ '@/components/MailSettingsGeneral/MailSettingsGeneral'
    ),
  FolderManagement: () =>
    import(
      /* webpackChunkName: "settings" */ '@/components/FolderManagement/FolderManagement'
    ),
  Domains: () =>
    import(/* webpackChunkName: "settings" */ '@/components/Domains'),
  AddDomain: () =>
    import(/* webpackChunkName: "settings" */ '@/components/AddDomain'),
  FolderManagementActions: () =>
    import(
      /* webpackChunkName: "settings" */ '@/components/FolderManagementActions/FolderManagementActions'
    ),
  Encryption: () =>
    import(
      /* webpackChunkName: "settings" */ '@/components/Settings/Encryption'
    ),
  Subscription: () =>
    import(
      /* webpackChunkName: "settings" */ '@/components/Settings/Subscription'
    ),
  PGPKeyring: () =>
    import(
      /* webpackChunkName: "settings" */ '@/components/PGPKeyring/PGPKeyring'
    ),
  KeyDetails: () =>
    import(
      /* webpackChunkName: "settings" */ '@/components/KeyDetails/KeyDetails'
    ),
  KeyDetailHeader: () =>
    import(
      /* webpackChunkName: "settings" */ '@/components/KeyDetailHeader/KeyDetailHeader'
    ),
  ContactGroupManagement: () =>
    import(
      /* webpackChunkName: "settings" */ '@/components/ContactGroupManagement/ContactGroupManagement'
    ),
  ContactGroupActions: () =>
    import(
      /* webpackChunkName: "settings" */ '@/components/ContactGroupActions/ContactGroupActions'
    ),
  AccountSettings: () =>
    import(
      /* webpackChunkName: "settings" */ '@/components/AccountSettings/AccountSettings'
    ),
  ImapTokens: () =>
    import(
      /* webpackChunkName: "settings" */ '@/components/ImapTokens/ImapTokens'
    ),
  ImapTokensHeader: () =>
    import(
      /* webpackChunkName: "settings" */ '@/components/ImapTokensHeader/ImapTokensHeader'
    ),
  FiltersSettings: () =>
    import(
      /* webpackChunkName: "settings" */ '@/components/FiltersSettings/FiltersSettings'
    ),
  FiltersActions: () =>
    import(
      /* webpackChunkName: "settings" */ '@/components/FiltersActions/FiltersActions'
    ),
  RecoveryEmailConfirmation: () =>
    import(
      /* webpackChunkName: "settings" */ '@/components/RecoveryEmailConfirmation/RecoveryEmailConfirmation'
    ),
  EmailMigration: () =>
    import(
      /* webpackChunkName: "settings" */ '@/components/Settings/EmailMigration'
    ),
  Tutorials: () =>
    import(
      /* webpackChunkName: "tutorials" */ '@/components/Settings/Tutorials'
    ),
  GroupSubscriptionPage: () =>
    import(
      /* webpackChunkName: "settings" */ '@/pages/GroupSubscriptionPage/GroupSubscriptionPage'
    ),

  AliasesPage: () =>
    import(/* webpackChunkName: "aliases" */ '@/pages/Aliases'),
  BurnerAlias: () =>
    import(
      /* webpackChunkName: "aliases" */ '@/components/BurnerAlias/BurnerAlias'
    ),
  CustomAliasesHeader: () =>
    import(
      /* webpackChunkName: "aliases" */ '@/components/CustomAliasesHeader'
    ),
  CustomAliases: () =>
    import(
      /* webpackChunkName: "aliases" */ '@/components/CustomAliases/CustomAliases'
    ),
};

export default [
  {
    path: '/cookies-not-enabled',
    name: namedRoutes.COOKIES_NOT_ENABLED,
    component: components.CookiesNotEnabled,
    meta: {
      access: 'public',
      title: (app) => app.$gettext('Cookies not enabled'),
    },
    props: true,
  },
  {
    path: '/login',
    name: namedRoutes.LOGIN,
    component: components.LoginPage,
    meta: {
      access: 'unauthenticated',
      title: (app) => app.$gettext('Login'),
    },
  },
  {
    path: '/signup',
    name: namedRoutes.SIGNUP_DEFAULT,
    component: components.DefaultSignupFlow,
    meta: {
      access: 'unauthenticated',
      title: (app) => app.$gettext('Signup'),
      featureFlag: FEATURE_SIGNUP,
    },
  },
  {
    path: '/custom-domain-signup/:customDomainVerifier?',
    name: namedRoutes.SIGNUP_CUSTOM_DOMAIN,
    component: components.CustomDomainSignupFlow,
    meta: {
      access: 'unauthenticated',
      title: (app) => app.$gettext('Signup'),
      featureFlag: FEATURE_SIGNUP,
    },
    props: (route) => ({
      customDomainVerifier: route.query.customDomainVerifier,
    }),
  },
  {
    path: '/signup/feedback',
    name: namedRoutes.SIGNUP_FEEDBACK,
    component: components.SignupFeedback,
    meta: {
      access: 'authenticated',
      title: (app) => app.$gettext('Signup feedback'),
    },
  },
  {
    path: '/signup/usernameError',
    name: namedRoutes.SIGNUP_USERNAME_ERROR,
    component: components.SignupUsernameError,
    meta: {
      access: 'unauthenticated',
      title: (app) => app.$gettext('Signup'),
    },
    props: true,
  },
  {
    path: '/signup/:couponCode',
    name: namedRoutes.SIGNUP_WITH_COUPON,
    component: components.CouponSignupFlow,
    meta: {
      access: 'unauthenticated',
      title: (app) => app.$gettext('Signup'),
      featureFlag: FEATURE_SIGNUP,
    },
  },
  {
    path: '/password-recovery',
    name: namedRoutes.PASSWORD_RECOVERY_INITIATE,
    component: components.PasswordRecoveryInitiate,
    meta: {
      access: 'public',
      title: (app) => app.$gettext('Reset your password'),
      featureFlag: FEATURE_RECOVERY,
    },
    props: true,
  },
  {
    path: '/password-recovery/confirmation',
    name: namedRoutes.PASSWORD_RECOVERY_CONFIRMATION,
    component: components.PasswordRecoveryConfirmation,
    meta: {
      access: 'public',
      title: (app) => app.$gettext('Reset your password'),
      featureFlag: FEATURE_RECOVERY,
    },
  },
  {
    path: '/password-recovery/set-new-password',
    name: namedRoutes.PASSWORD_RECOVERY_SET_NEW_PASSWORD,
    component: components.PasswordRecoverySetNewPassword,
    meta: {
      access: 'public',
      title: (app) => app.$gettext('Reset your password'),
      featureFlag: FEATURE_RECOVERY,
    },
    props: (route) => ({
      recoveryCode: route.query.recovery_code,
      user: route.query.user,
    }),
  },
  {
    path: '/password-recovery/new-recovery-code',
    name: namedRoutes.PASSWORD_RECOVERY_NEW_RECOVERY_CODE,
    component: components.PasswordRecoveryNewRecoveryCode,
    meta: {
      access: 'public',
      title: (app) => app.$gettext('One-time recovery code'),
      featureFlag: FEATURE_RECOVERY,
    },
    props: true,
  },
  {
    path: '/user/recoveryEmail',
    name: namedRoutes.RECOVERY_EMAIL_CONFIRMATION,
    component: components.RecoveryEmailConfirmation,
    meta: {
      access: 'public',
      title: (app) => app.$gettext('Confirm recovery email'),
      featureFlag: FEATURE_RECOVERY,
    },
    props: (route) => ({
      queryUsername: route.query.username,
      confirmationKey: route.query.token,
    }),
  },
  {
    path: '/user/recoveryEmail/:token',
    name: namedRoutes.RECOVERY_EMAIL_CONFIRMATION_OLD,
    component: components.RecoveryEmailConfirmation,
    meta: {
      access: 'authenticated',
      title: (app) => app.$gettext('Confirm recovery email'),
      featureFlag: FEATURE_RECOVERY,
    },
    props: (route) => ({
      confirmationKey: route.params.token,
    }),
  },
  {
    path: '/deleted-account',
    name: namedRoutes.DELETED_USER,
    component: components.DeletedUserConfirmation,
    meta: {
      access: 'public',
      title: (app) => app.$gettext('Deleted account'),
    },
  },
  {
    path: '/support',
    name: namedRoutes.SIGNUP_SUPPORT,
    component: components.SignupSupport,
    meta: {
      access: 'public',
      title: (app) => app.$gettext('Contact Support'),
    },
  },
  {
    path: '/',
    redirect: '/mail',
  },
  {
    path: '/mail',
    name: namedRoutes.MAIL,
    component: components.MailPage,
    redirect: '/mail/folders/INBOX',
    children: [
      {
        path: 'compose',
        name: namedRoutes.MAIL_COMPOSE,
        components: {
          appHeader: components.ComposeHeader,
          main: components.Compose,
        },
        meta: {
          title: (app) => app.$gettext('Compose'),
        },
      },
      {
        path: 'search',
        name: namedRoutes.MAIL_SEARCH,
        components: {
          appHeader: components.MailHeader,
          main: components.MessageList,
          pane: components.Message,
        },
        meta: {
          title: (app) => app.$gettext('Search'),
        },
      },
      {
        path: 'inbox',
        redirect: '/mail/folders/INBOX',
      },
      {
        path: 'folders/:folder',
        name: namedRoutes.MAIL_FOLDER,
        components: {
          appHeader: components.MailHeader,
          main: components.MessageList,
          pane: components.Message,
        },
        meta: {
          title: (app) => {
            const folder = app.$store.getters.activeFolder;
            if (folder.id) {
              const folderUtilsMixin = new FolderUtilsMixin();
              const displayName = folderUtilsMixin.getFolderName.call(
                app,
                folder
              );
              const count = folder.unread_messages_count || 0;

              return count > 0 ? `(${count}) ${displayName}` : displayName;
            }
          },
        },
      },
      {
        path: ':folder/:message',
        name: namedRoutes.MAIL_MESSAGE,
        components: {
          appHeader: components.MessageDetailHeader,
          main: components.Message,
        },
        meta: {
          title: (app) => {
            const message = app.$store.getters.activeMessage;
            return (message && message.subject) || '';
          },
        },
      },
    ],
  },
  {
    path: '/contacts',
    redirect: '/contacts/groups/all',
    name: namedRoutes.CONTACTS,
    component: components.ContactsPage,
    children: [
      {
        path: 'groups/:group',
        name: namedRoutes.CONTACTS_GROUP,
        components: {
          appHeader: components.ContactHeader,
          main: components.ContactGroupView,
        },
        meta: {
          title: (app) => app.$gettext('Contacts'),
        },
      },
      {
        path: 'search',
        name: namedRoutes.CONTACTS_SEARCH,
        components: {
          appHeader: components.ContactHeader,
          main: components.ContactGroupView,
        },
        meta: {
          title: (app) => app.$gettext('Search'),
        },
      },
      {
        path: ':contact',
        name: namedRoutes.CONTACTS_CONTACT,
        components: {
          appHeader: components.ContactDetailHeader,
          main: components.Contact,
        },
        meta: {
          title: (app) => {
            const contact = app.$store.getters.activeContact;
            return contact?.display_name;
          },
        },
      },
    ],
  },
  {
    path: '/settings',
    name: namedRoutes.SETTINGS,
    component: components.SettingsPage,
    redirect: '/settings/account',
    children: [
      {
        path: 'account',
        name: namedRoutes.SETTINGS_ACCOUNT,
        components: {
          main: components.AccountSettings,
        },
        meta: {
          title: (app) => app.$gettext('Account settings'),
        },
      },
      {
        path: 'mail/general',
        name: namedRoutes.SETTINGS_MAIL,
        components: {
          main: components.MailSettingsGeneral,
        },
        meta: {
          title: (app) => app.$gettext('Mail settings'),
        },
      },
      {
        path: 'mail/folders',
        name: namedRoutes.SETTINGS_MAIL_FOLDERS,
        components: {
          main: components.FolderManagement,
          appHeaderActions: components.FolderManagementActions,
        },
        meta: {
          title: (app) => app.$gettext('Folder settings'),
        },
      },
      {
        path: 'mail/domain',
        name: namedRoutes.SETTINGS_DOMAINS,
        components: {
          appHeaderActions: components.AddDomain,
          appHeader: components.SettingsHeader,
          main: components.Domains,
        },
        meta: {
          access: 'manager',
          title: (app) => app.$gettext('Domains'),
        },
        props: {
          appHeaderActions: { appHeader: true },
        },
      },
      {
        path: 'group-subscription',
        name: namedRoutes.SETTINGS_GROUP_SUBSCRIPTIONS,
        components: {
          main: components.GroupSubscriptionPage,
        },
        meta: {
          access: 'manager',
          title: (app) => app.$gettext('Group subscription'),
        },
      },
      {
        path: 'encryption',
        name: namedRoutes.SETTINGS_ENCRYPTION,
        components: {
          main: components.Encryption,
        },
        meta: {
          title: (app) => app.$gettext('Encryption'),
        },
      },
      {
        path: 'subscription',
        name: namedRoutes.SETTINGS_SUBSCRIPTION,
        components: {
          main: components.Subscription,
        },
        meta: {
          access: 'manager',
          title: (app) => app.$gettext('Subscription'),
        },
      },
      {
        path: 'tutorials',
        name: namedRoutes.SETTINGS_TUTORIALS,
        components: {
          main: components.Tutorials,
        },
        meta: {
          title: (app) => app.$gettext('Guides and tutorials'),
        },
      },
      {
        path: 'pgp-keyring',
        name: namedRoutes.SETTINGS_PGP_KEYRING,
        components: {
          main: components.PGPKeyring,
        },
        meta: {
          title: (app) => app.$gettext('PGP keyring'),
        },
      },
      {
        path: 'pgp-keyring/search',
        name: namedRoutes.SETTINGS_PGP_KEYRING_SEARCH,
        components: {
          main: components.PGPKeyring,
        },
        meta: {
          title: (app) => app.$gettext('Search'),
        },
        /* props don't work properly in children */
      },
      {
        path: 'pgp-keyring/:fingerprint',
        name: namedRoutes.SETTINGS_PGP_KEYRING_KEY,
        components: {
          main: components.KeyDetails,
          appHeader: components.KeyDetailHeader,
        },
        meta: {
          title: (app) => app.$gettext('Key details'),
        },
      },
      {
        path: 'mail/imap',
        name: namedRoutes.SETTINGS_IMAP_TOKENS,
        components: {
          main: components.ImapTokens,
          appHeader: components.ImapTokensHeader,
        },
        meta: {
          title: (app) => app.$gettext('Trusted devices/IMAP'),
          featureFlag: FEATURE_IMAP_TOKENS,
        },
      },
      {
        path: 'mail/filters',
        name: namedRoutes.SETTINGS_MAIL_FILTERS,
        components: {
          main: components.FiltersSettings,
          appHeaderActions: components.FiltersActions,
        },
        meta: {
          title: (app) => app.$gettext('Filters'),
          featureFlag: FEATURE_FILTERS,
        },
      },
      {
        path: 'contact/contact-groups',
        name: namedRoutes.SETTINGS_CONTACT_CONTACTGROUPS,
        components: {
          main: components.ContactGroupManagement,
          appHeaderActions: components.ContactGroupActions,
        },
        meta: {
          title: (app) => app.$gettext('Contact groups'),
        },
      },
      {
        path: 'email-migration',
        name: namedRoutes.SETTINGS_EMAIL_MIGRATION,
        components: {
          main: components.EmailMigration,
        },
        meta: {
          title: (app) => app.$gettext('Email Migration'),
        },
      },
    ].map((route) => ({
      ...route,
      components: {
        ...route.components,
        appHeader: route.components.appHeader || components.SettingsHeader,
      },
    })),
  },
  {
    path: '/subscription-expired',
    name: namedRoutes.SUBSCRIPTION_EXPIRED,
    component: components.SubscriptionExpiredPage,
    meta: {
      access: 'manager',
      title: (app) => app.$gettext('Subscription expired'),
    },
  },
  {
    path: '/aliases',
    name: namedRoutes.ALIASES,
    component: components.AliasesPage,
    redirect: { name: namedRoutes.ALIASES_PERSONAL },
    children: [
      {
        path: 'personal',
        name: namedRoutes.ALIASES_PERSONAL,
        components: {
          main: components.CustomAliases,
          appHeader: components.SettingsHeader,
          appHeaderActions: components.CustomAliasesHeader,
        },
        props: {
          main: { type: 'personal' },
          appHeaderActions: { type: 'personal' },
        },
        meta: {
          title: (app) => app.$gettext('Personal aliases'),
        },
      },
      {
        path: 'domain',
        name: namedRoutes.ALIASES_DOMAIN,
        components: {
          main: components.CustomAliases,
          appHeader: components.SettingsHeader,
          appHeaderActions: components.CustomAliasesHeader,
        },
        props: {
          main: { type: 'group' },
          appHeaderActions: { type: 'group' },
        },
        meta: {
          title: (app) => app.$gettext('Domain aliases'),
        },
      },
      {
        path: 'burner',
        name: namedRoutes.ALIASES_BURNER,
        components: {
          main: components.BurnerAlias,
          appHeader: components.SettingsHeader,
        },
        meta: {
          title: (app) => app.$gettext('Burner alias'),
        },
      },
    ],
  },
  {
    path: '*',
    component: components.MailPage,
    children: [
      {
        path: '/',
        components: {
          appHeader: components.MailHeader,
          main: components.NotFound,
        },
        meta: {
          title: (app) => app.$gettext('Page not found'),
        },
      },
    ],
  },
];
