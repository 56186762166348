
  import Icon from '@/components/Icon/Icon.vue';
  import Vue, { PropType } from 'vue';

  export default Vue.extend({
    name: 'InputField',
    components: {
      Icon,
    },
    inheritAttrs: false,
    model: {
      prop: 'value',
      event: 'input',
    },
    props: {
      focused: {
        type: Boolean,
        default: false,
      },
      hasHiddenLabel: {
        type: Boolean,
        default: false,
      },
      icon: {
        type: String,
        default: '',
      },
      id: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      showCustomValidity: {
        type: Boolean,
        default: false,
      },
      validate: {
        type: Boolean,
        default: false,
      },
      validationErrorMessage: {
        type: String,
        default: '',
      },
      value: {
        type: String,
        required: true,
      },
      disabled: {
        type: Boolean,
        required: false,
      },
      autocapitalize: {
        type: String as PropType<string | undefined>,
        required: false,
        default: undefined,
      },
      maxlength: {
        type: Number as PropType<number | undefined>,
        required: false,
        default: undefined,
      },
    },
    data() {
      return {
        isFocused: this.focused,
        valid: false,
      };
    },
    computed: {
      input(): HTMLInputElement {
        return this.$refs.input as HTMLInputElement;
      },
      inputLabelId(): string {
        return `${this.id}-label`;
      },
      isInvalid(): boolean {
        return this.validate && !this.valid;
      },
    },
    watch: {
      value() {
        this.setCustomValidity();

        /* when the value is updated programatically, the input is not updated
           right away. so we check for validity on the next tick to make sure it
           has the right value. */
        this.$nextTick(() => {
          this.valid = this.input.checkValidity();
        });
      },
      showCustomValidity() {
        this.setCustomValidity();

        /* somehow when setting the customvalidity, the input element does not
           report that it is invalid until the next event loop, so we delay the
           check a bit. */
        window.setTimeout(() => {
          this.valid = this.input.checkValidity();
        }, 0);
      },
    },
    mounted() {
      this.valid = this.input.checkValidity();
    },
    methods: {
      updateInput(input: string) {
        this.$emit('input', input);
      },
      emitBlur() {
        this.isFocused = false;
        this.$emit('blur');
      },
      emitFocus(ev: Event) {
        this.isFocused = true;
        this.$emit('focus', ev.target);
      },
      focus() {
        this.input.focus();
      },
      blur() {
        this.input.blur();
      },
      setCustomValidity() {
        this.input.setCustomValidity(
          this.showCustomValidity ? this.validationErrorMessage : ''
        );
      },
    },
  });
