
  import Vue, { PropType } from 'vue';
  export default Vue.extend({
    props: {
      symbol: {
        type: String,
        required: true,
      },
      title: {
        type: String as PropType<string | null>,
        default: null,
      },
      size: {
        type: String,
        default: 'default',
      },
    },
    data() {
      return {
        href: null,
        viewBox: null,
      };
    },
    watch: {
      symbol(this: any) {
        this.loadSvg();
      },
    },
    async created(this: any) {
      try {
        this.loadSvg();
      } catch (err) {
        // TODO: fallback for icon not able to load
        // eslint-disable-next-line no-console
        console.error(err);
      }
    },
    methods: {
      async loadSvg(this: any) {
        const { default: svg } = await import(
          /* webpackChunkName: "icon" */
          /* webpackMode: "lazy-once" */
          `../../../public/svgs/${this.symbol}.svg`
        );
        this.href = svg.url.substr(svg.url.indexOf('#'));
        this.viewBox = svg.viewBox;
      },
    },
  });
