import Vue from 'vue';
import { Module } from 'vuex';
import api from '@/api';

const SET_IMAP_TOKENS = 'ENTITIES:IMAP_TOKENS:SET_IMAP_TOKEN';
const ADD_IMAP_TOKEN = 'ENTITIES:IMAP_TOKENS:ADD_IMAP_TOKEN';
const REMOVE_IMAP_TOKEN = 'ENTITIES:IMAP_TOKENS:REMOVE_IMAP_TOKEN';

export const SET_IMAP_TOKENS_NS = `imapTokens/${SET_IMAP_TOKENS}`;
export const ADD_IMAP_TOKEN_NS = `imapTokens/${ADD_IMAP_TOKEN}`;
export const REMOVE_IMAP_TOKEN_NS = `imapTokens/${REMOVE_IMAP_TOKEN}`;

interface ImapTokensState {
  byIdentifier: {
    [identifier: string]: ImapToken;
  };
}

const imapTokensModule: Module<ImapTokensState, any> = {
  namespaced: true,

  state: {
    byIdentifier: {},
  },

  getters: {
    imapTokens: (state) => Object.values(state.byIdentifier),
  },

  mutations: {
    [SET_IMAP_TOKENS](state, { imapTokens }: { imapTokens: ImapToken[] }) {
      state.byIdentifier = imapTokens.reduce(
        (byIdentifier, imapToken) => ({
          ...byIdentifier,
          [imapToken.identifier]: imapToken,
        }),
        {}
      );
    },
    [ADD_IMAP_TOKEN](state, { imapToken }: { imapToken: ImapToken }) {
      state.byIdentifier = {
        ...state.byIdentifier,
        [imapToken.identifier]: imapToken,
      };
    },
    [REMOVE_IMAP_TOKEN](state, { identifier }: { identifier: string }) {
      Vue.delete(state.byIdentifier, identifier);
    },
  },

  actions: {
    async loadImapTokens({ commit }): Promise<ImapToken[]> {
      const imapTokens = await api.imapTokens.list();
      commit(SET_IMAP_TOKENS, { imapTokens });
      return imapTokens;
    },

    async createImapToken(
      { commit },
      {
        identifier,
        password,
      }: {
        identifier: string;
        password: string;
      }
    ): Promise<ImapToken> {
      const imapToken = await api.imapTokens.create({ identifier, password });
      commit(ADD_IMAP_TOKEN, { imapToken });
      return imapToken;
    },

    async deleteImapToken(
      { commit },
      { identifier, password }: { identifier: string; password: string }
    ) {
      await api.imapTokens.delete({ identifier, password });
      commit(REMOVE_IMAP_TOKEN, { identifier });
    },
  },
};

export default imapTokensModule;
