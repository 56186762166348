export const BROADCAST_AUTHENTICATION = 'authentication';

const BROADCAST_CHANNEL = 'sm-broadcast-channel';

const identity = Math.floor(Math.random() * 1000000);

export function initializeBroadcastListener(store: any) {
  if (!window.BroadcastChannel) return;

  const bc = new window.BroadcastChannel(BROADCAST_CHANNEL);
  bc.addEventListener('message', async ({ data }) => {
    if (data.senderIdentity === identity) return;
    if (data.type !== BROADCAST_AUTHENTICATION) return;

    const authenticatedMismatch =
      data.payload.is_authenticated !==
      store.state.authentication.isAuthenticated;
    const userMismatch =
      Boolean(data.payload.user) &&
      data.payload.user.id !== store.state.authentication.user.id;

    if (authenticatedMismatch || userMismatch) {
      await store.dispatch('unloadAndRemoveAllFunctions');
      location.reload();
    }
  });
}

export function broadcastMessage(type: string, payload: any) {
  if (!window.BroadcastChannel) return;
  const bc = new window.BroadcastChannel(BROADCAST_CHANNEL);
  bc.postMessage({ type, payload, senderIdentity: identity });
}
