<script>
  import store from '@/store';
  import { logWarning } from '@/mixins/error-logger-mixin';

  const debug = process.env.NODE_ENV !== 'production';

  export default {
    name: 'FeatureToggle',
    functional: true,
    props: {
      id: {
        type: String,
        required: true,
      },
      overrideFlag: {
        type: Boolean,
        default: false,
      },
    },
    render(createElement, context) {
      const haveFeatures = store.getters['features/haveFeatures']?.();
      const feature = store.getters['features/featureById']?.(context.props.id);

      const showFeature = haveFeatures
        ? context.props.overrideFlag || feature?.enabled
        : debug;

      if (showFeature) {
        return context.slots().default || null;
      } else if (!feature && haveFeatures) {
        logWarning(`Disabling unknown feature ${context.props.id}`);
      }

      return context.slots().fallback || null;
    },
  };
</script>
