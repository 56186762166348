import api from '../../../api';
import * as types from '../contacts-mutation-types';

const systemGroups = ['all', 'favorites'];

const state = {
  activeContactGroupId: 'all',
  contactGroups: [],
  contactGroupsLoaded: false,
  loadingContactGroupsError: null,
};

const getters = {
  activeContactGroup: (state) =>
    state.contactGroups.find(
      (group) => group.id === state.activeContactGroupId
    ) || {},
  contactUserGroups: (state, getters) =>
    getters.contactGroups.filter((group) => !systemGroups.includes(group.id)),
  contactGroups: (state) =>
    [...state.contactGroups].sort(naturalSortContactGroups),
  loadingContactGroupsError: (state) => state.loadingContactGroupsError,
};

function naturalSortContactGroups(a, b) {
  if (a.id === 'all' || b.id === 'all') {
    return a.id === 'all' ? -1 : 1;
  }
  if (a.id === 'favorites' || b.id === 'favorites') {
    return a.id === 'favorites' ? -1 : 1;
  }
  return a.name.localeCompare(
    b.name,
    navigator.languages[0] || navigator.language,
    {
      numeric: true,
      ignorePunctuation: true,
    }
  );
}

const actions = {
  async getContactGroups({ commit }) {
    const contactGroups = await api.contactGroups.all();
    commit(types.SET_CONTACT_GROUPS, { contactGroups });
    commit(types.SET_CONTACT_GROUPS_LOADED);
    return contactGroups;
  },
  setActiveContactGroup({ commit }, { id }) {
    commit(types.SET_ACTIVE_CONTACT_GROUP, { id: id || 'all' });
  },
  async addContactGroup({ commit }, { contactGroup }) {
    const response = await api.contactGroups.create({ contactGroup });
    commit(types.ADD_CONTACT_GROUP, { contactGroup: response });
    return response;
  },
  async updateContactGroup({ commit }, { id, properties }) {
    const contactGroup = await api.contactGroups.update({ id, properties });
    commit(types.UPDATE_CONTACT_GROUP, { id, properties });
    return contactGroup;
  },
  async removeContactGroup({ commit }, { id }) {
    const contactGroup = await api.contactGroups.delete({ id });
    commit(types.REMOVE_CONTACT_GROUP, { id });
    return contactGroup;
  },
  setLoadingContactGroupsError({ commit }, { error }) {
    commit(types.SET_LOADING_CONTACT_GROUPS_ERROR, { error });
  },
};

const mutations = {
  [types.SET_ACTIVE_CONTACT_GROUP](state, { id }) {
    state.activeContactGroupId = String(id);
  },
  [types.SET_CONTACT_GROUPS_LOADED](state) {
    state.contactGroupsLoaded = true;
  },
  [types.SET_CONTACT_GROUPS](state, { contactGroups }) {
    state.contactGroups = contactGroups.map((group) => ({
      ...group,
      id: String(group.id),
    }));
  },
  [types.ADD_CONTACT_GROUP](state, { contactGroup }) {
    state.contactGroups.push({
      ...contactGroup,
      id: String(contactGroup.id),
    });
  },
  [types.UPDATE_CONTACT_GROUP](state, { id, properties }) {
    const index = state.contactGroups.findIndex(
      (contactGroup) => contactGroup.id === id
    );
    state.contactGroups.splice(index, 1, {
      ...state.contactGroups[index],
      ...properties,
      id: String(properties.id || state.contactGroups[index].id),
    });
  },
  [types.REMOVE_CONTACT_GROUP](state, { id }) {
    const index = state.contactGroups.findIndex((group) => group.id === id);
    state.contactGroups.splice(index, 1);
  },
  [types.CHANGE_CONTACT_COUNT](state, { groupIds, count }) {
    groupIds.forEach((id) => {
      const group = state.contactGroups.find(
        (contactGroup) => contactGroup.id === id
      );
      group.contact_count = Math.max(0, group.contact_count + count);
    });
  },
  [types.SET_LOADING_CONTACT_GROUPS_ERROR](state, { error }) {
    state.loadingContactGroupsError = error;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
