/**
 * https://vuex.vuejs.org/
 *
 * State - this single object contains all your application level state and serves as the "single source of truth"
 * Getters - computed properties for stores
 * Mutations - functions where state modifications are performed
 * Actions - functions which contain arbitrary asynchronous operations and which commit mutations
 *
 */
import Vue from 'vue';
import Vuex from 'vuex';
import config from './config';

Vue.use(Vuex);

const store = new Vuex.Store(config);

export default store;

// This is the initial store state before it is enriched with any data from api calls etc, used for resetting (below).
const serializedState = JSON.stringify(store.state);

// TODO: Use cloneDeep for tests as described here:
//       https://vue-test-utils.vuejs.org/guides/using-with-vuex.html#testing-a-running-store
export function resetState() {
  store.replaceState(JSON.parse(serializedState));
}
