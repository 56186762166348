import { Module } from 'vuex';
import api from '@/api';
import * as featureFlags from '@/lib/featureFlags';

import {
  ENTITIES_FEATURES_SET,
  ENTITIES_FEATURES_LOADED_FEATURES,
} from './entities-mutation-types';

interface FeaturesState {
  byId: {
    [id: string]: Feature;
  };
  loadedFeatures: Promise<Feature[]> | null;
}

const FLAGS = featureFlags as { [key: string]: string };
const ALL_FEATURES = Object.keys(FLAGS);

const featuresModule: Module<FeaturesState, any> = {
  namespaced: true,

  state: {
    byId: {},
    loadedFeatures: null,
  },

  getters: {
    allFeatures: (state) => Object.values(state.byId),
    chargebeeSite: (state, getters) => {
      const canary = getters.featureById(featureFlags.FEATURE_IS_CANARY);
      return canary && canary.enabled ? 'startmail-test' : 'startmail';
    },
    featureById: (state) => (id: string) => {
      if (Object.prototype.hasOwnProperty.call(state.byId, id)) {
        return state.byId[id];
      }
      // If at lease some feature toggles are configured (so the request for features didn't fail), warn of unconfigured.
      if (Object.keys(state.byId).length !== 0) {
        // eslint-disable-next-line no-console
        console.warn(
          `Unconfigured feature flag ${id} requested. Returning disabled.`
        );
      }
      return {
        id,
        enabled: false,
      };
    },
    haveFeatures: (state) => () => Object.keys(state.byId).length !== 0,
  },

  mutations: {
    [ENTITIES_FEATURES_SET](state, { features }) {
      state.byId = features.reduce(
        (all: { [id: string]: Feature }, feature: Feature) => ({
          ...all,
          [feature.id]: feature,
        }),
        {}
      );
    },
    [ENTITIES_FEATURES_LOADED_FEATURES](state, { promise }) {
      state.loadedFeatures = promise;
    },
  },

  actions: {
    async getFeatures({ state, commit }, { forceReload } = {}) {
      if (!forceReload && state.loadedFeatures) {
        return state.loadedFeatures;
      }

      const allFlags = ALL_FEATURES.map((key) => FLAGS[key]);
      const promise = api.features.get({ flags: allFlags }).then((features) => {
        commit(ENTITIES_FEATURES_SET, { features });
        return features;
      });
      commit(ENTITIES_FEATURES_LOADED_FEATURES, { promise });
      return promise;
    },
  },
};

export default featuresModule;
