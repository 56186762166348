const UI_BREAKPOINTS = {
  SIDEBAR_COLLAPSE: 850,
  SCREEN_XS: 600,
};

const UI_MEDIAQUERIES = {
  SIDEBAR_COLLAPSED: `screen and (max-width: ${
    UI_BREAKPOINTS.SIDEBAR_COLLAPSE - 1
  }px)`,
  SIDEBAR_NOT_COLLAPSED: `screen and (min-width: ${UI_BREAKPOINTS.SIDEBAR_COLLAPSE}px)`,
  SINGLE_LINE_BREAKPOINT: 'screen and (min-width: 1200px)',
  PRINT: 'print',
  SCREEN_XS: `screen and (max-width: ${UI_BREAKPOINTS.SCREEN_XS}px)`,
};

export { UI_MEDIAQUERIES };
