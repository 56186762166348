<template>
  <div class="modal__footer">
    <button
      v-test:cancel-button
      v-if="!hideCancel"
      :disabled="displayLoadingState"
      type="button"
      class="button button--link"
      :class="{ 'button--error': isDangerousCancel }"
      @click="onCancelClicked"
    >
      {{ cancelText }}
    </button>
    <LoadingButton
      v-test:confirm-button
      v-if="!hideOk"
      :type="okButtonType"
      :loading="displayLoadingState"
      :disabled="disabled"
      class="button button--primary"
      :class="{ 'button--error': isDangerous, 'button--success': isSuccess }"
      @click="onOkClicked"
    >
      {{ okText }}
    </LoadingButton>
  </div>
</template>

<script>
  import LoadingButton from '@/components/LoadingButton';

  export default {
    //we use the inject / provide api to retrieve the injected modal state (https://vuejs.org/v2/api/#provide-inject)
    inject: {
      modalState: {
        from: 'modalState',
        default: { loading: false },
      },
    },
    components: {
      LoadingButton,
    },
    props: {
      isDangerous: {
        type: Boolean,
        default: false,
      },
      isDangerousCancel: {
        type: Boolean,
        default: false,
      },
      isSuccess: {
        type: Boolean,
        default: false,
      },
      hideOk: {
        type: Boolean,
        default: false,
      },
      hideCancel: {
        type: Boolean,
        default: false,
      },
      okText: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean,
      },
      cancelText: {
        type: String,
        default() {
          return this.$gettext('Cancel');
        },
        required: false,
      },
    },
    data() {
      return {
        displayLoadingState: false,
        displayTimeout: 0,
      };
    },
    computed: {
      //make sure that deletion is not the default action
      okButtonType() {
        return this.isDangerous ? 'button' : 'submit';
      },
    },
    watch: {
      //We only want to show the loading / disabled state if the action takes longer then x milliseconds
      'modalState.loading'(newValue, oldValue) {
        clearTimeout(this.displayTimeout);
        if (newValue) {
          this.displayTimeout = setTimeout(() => {
            this.displayLoadingState = true;
          }, 300);
        } else if (oldValue) {
          this.displayLoadingState = false;
        }
      },
    },
    created() {
      this.displayTimeout = 0;
    },
    methods: {
      onCancelClicked() {
        if (!this.modalState.loading) {
          this.$emit('cancelClicked');
        }
      },
      onOkClicked(event) {
        if (!this.modalState.loading) {
          this.$emit('okClicked', event);
        }
      },
    },
  };
</script>
