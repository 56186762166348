import { SET_ACTIVE_SEARCH_QUERY } from '@/store/search/search-mutation-types';
import { SET_SELECTED_MESSAGES } from '@/store/mail/modules/messages';
import { RESET_SORT_ORDER } from '@/store/sorting/sorting-mutation-types';
import { SET_PAGE, SET_TOTAL } from '@/store/pagination';

export const MAIL_FOLDERS_SET_ACTIVE_FOLDER = 'MAIL:FOLDERS:SET_ACTIVE_FOLDER';

export default {
  state: {
    activeFolderId: null,
  },

  getters: {
    activeFolder: (state, getters) =>
      getters.folderById(state.activeFolderId) || {},
    activeFolderPath(state, getters) {
      function getFolderPath(folder) {
        if (!folder) return [];

        return [
          ...getFolderPath(getters.folderById(folder.parent_id)),
          folder.id,
        ];
      }

      return getFolderPath(getters.activeFolder);
    },
  },

  actions: {
    setActiveFolder({ commit, state }, { folderId }) {
      commit(SET_ACTIVE_SEARCH_QUERY, { query: null });

      if (folderId !== state.activeFolderId) {
        commit(MAIL_FOLDERS_SET_ACTIVE_FOLDER, { folderId });
        commit(`pagination/${SET_PAGE}`, 1);
        commit(`pagination/${SET_TOTAL}`, null);
        commit(RESET_SORT_ORDER);
        commit(SET_SELECTED_MESSAGES, { ids: [] });
      }

      return state.activeFolderId;
    },
  },

  mutations: {
    [MAIL_FOLDERS_SET_ACTIVE_FOLDER](state, { folderId }) {
      state.activeFolderId = folderId;
    },
  },
};
