
  import Vue from 'vue';
  import { FEATURE_IS_CANARY } from '@/lib/featureFlags';
  import Icon from '@/components/Icon/Icon.vue';
  import { deleteCookie } from '@/lib/cookie';

  export default Vue.extend({
    name: 'Logo',
    components: {
      Icon,
    },
    props: {
      big: {
        type: Boolean,
        required: false,
      },
    },
    data() {
      return {
        FEATURE_IS_CANARY,
      };
    },
    computed: {
      darkMode(): boolean {
        // Try to use the Mail store, but very carefully, because this
        // component is also used in other Vue apps (e.g. PPM) with a
        // different store or no store at all.
        return this.$store?.state?.settings?.darkMode ?? false;
      },
    },
    methods: {
      uncanarify() {
        if (window.confirm('Confirm to remove the canary cookie')) {
          deleteCookie('webmail_nfe_canary');
          document.location.reload();
        }
      },
    },
  });
