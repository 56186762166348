export const SET_ACTIVE_FOLDER = 'SET_ACTIVE_FOLDER';
export const SET_ACTIVE_MESSAGE = 'SET_ACTIVE_MESSAGE';
export const SET_DRAFT = 'SET_DRAFT';
export const SET_DRAFT_FROM_MESSAGE = 'SET_DRAFT_FROM_MESSAGE';
export const SENDING_DRAFT = 'SENDING_DRAFT';
export const SET_SELECTED_MESSAGES = 'SET_SELECTED_MESSAGES';
export const SET_ATTACHMENTS = 'SET_ATTACHMENTS';
export const ADD_ATTACHMENT = 'ADD_ATTACHMENT';
export const REMOVE_ATTACHMENT = 'REMOVE_ATTACHMENT';
export const UPDATE_ATTACHMENT = 'UPDATE_ATTACHMENT';
export const ADD_ATTACHMENT_PROGRESS = 'ADD_ATTACHMENT_PROGRESS';
export const UPDATE_ATTACHMENT_PROGRESS = 'UPDATE_ATTACHMENT_PROGRESS';
export const REMOVE_ATTACHMENT_PROGRESS = 'REMOVE_ATTACHMENT_PROGRESS';
export const SET_KEY_STATE = 'SET_KEY_STATE';
export const REMOVE_RECIPIENT = 'REMOVE_RECIPIENT';
export const SET_ACTIVE_FILTERS = 'SET_ACTIVE_FILTERS';
