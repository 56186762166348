export default {
  bind,
  unbind,
};

// constant definitions

const COUNT_CLASS = 'draggable-count';
const DRAG_GHOST_CLASS = 'draggable-ghost';
const DRAGGABLE_ATTRIBUTE = 'draggable';
const DROP_TARGET_DATA_ATTRIBUTE = 'data-drop-target';
const DROP_TARGET_SELECTOR = `[${DROP_TARGET_DATA_ATTRIBUTE}]`;

let boundOnDrop;
let boundOnItemDragStart;
let elGhost;

// Vue Directive Methods

function bind(el, binding, v) {
  const draggedItem = v.context;

  el.setAttribute(DRAGGABLE_ATTRIBUTE, true);
  boundOnItemDragStart = onItemDragStart.bind(
    this,
    draggedItem,
    binding.value.onDropped
  );
  el.addEventListener('dragstart', boundOnItemDragStart);
  el.addEventListener('dragend', onItemDragEnd);
}

function unbind(el) {
  el.removeAttribute(DRAGGABLE_ATTRIBUTE);
  el.removeEventListener('dragstart', boundOnItemDragStart);
  el.removeEventListener('dragend', onItemDragEnd);
}

// Listeners for Drag Item

function onItemDragStart(draggedItem, onDropped, e) {
  // If elements are already selected and a drag is started on an unselected message,
  // we assume the user wants to drag that message as well: so we select it before we go on
  if (
    draggedItem.selectedMessages &&
    draggedItem.selectedMessages.length &&
    !draggedItem.isChecked
  ) {
    draggedItem.selectMessage({ id: draggedItem.message.id });
  }
  if (
    draggedItem.selectedContacts &&
    draggedItem.selectedContacts.length &&
    !draggedItem.isChecked
  ) {
    draggedItem.selectContact({
      contact: draggedItem.contact,
      value: true,
    });
  }

  // Create and attach ghost
  const text = draggedItem.message
    ? draggedItem.message.subject
    : draggedItem.contact.display_name;
  elGhost = document.createElement('div');
  elGhost.className = DRAG_GHOST_CLASS;
  elGhost.appendChild(document.createTextNode(text));
  document.body.appendChild(elGhost);

  let showBadge =
    (draggedItem.selectedMessages && draggedItem.selectedMessages.length > 1) ||
    (draggedItem.selectedContacts && draggedItem.selectedContacts.length > 1);
  if (showBadge) {
    elGhost.dataset.counter = draggedItem.selectedMessages
      ? draggedItem.selectedMessages.length
      : draggedItem.selectedContacts.length;
    elGhost.classList.add(COUNT_CLASS);
  }

  // setting the drag data is required: https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API
  e.dataTransfer.setData('text', '');
  e.dataTransfer.setDragImage(elGhost, 10, showBadge ? 25 : 10);

  // Activate droptargets
  // A listener for the dragenter and dragover events are used to indicate valid drop targets: if you want to allow a
  // drop, you must prevent the default handling by cancelling the event.
  // https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Drag_operations#droptargets
  let droptargets = document.querySelectorAll(DROP_TARGET_SELECTOR);

  boundOnDrop = onDrop.bind(this, onDropped, draggedItem);
  Array.from(droptargets).forEach(function (droptarget) {
    droptarget.addEventListener('dragenter', onDragEnter);
    droptarget.addEventListener('dragover', onDragOver);
    droptarget.addEventListener('dragleave', onDragLeave);
    droptarget.addEventListener('drop', boundOnDrop);
    droptarget.addEventListener('drop', onItemDragEnd);
  });
}

function onItemDragEnd() {
  let droptargets = document.querySelectorAll(DROP_TARGET_SELECTOR);

  Array.from(droptargets).forEach(function (droptarget) {
    if (droptarget.hasAttribute('data-is-active')) {
      droptarget.removeAttribute('data-is-active');
    }
    droptarget.removeEventListener('dragenter', onDragEnter);
    droptarget.removeEventListener('dragover', onDragOver);
    droptarget.removeEventListener('dragleave', onDragLeave);
    droptarget.removeEventListener('drop', boundOnDrop);
    droptarget.removeEventListener('drop', onItemDragEnd);
  });

  if (elGhost.parentNode !== null) {
    document.body.removeChild(elGhost);
  }
}

function onHover(droptarget) {
  droptarget.__vue__.childIsOpen = true;
}

// Listeners for Drop Target

function onDragEnter(e) {
  e.preventDefault();
  e.stopPropagation();

  if (!e.currentTarget.hasAttribute('data-is-active')) {
    clearTimeout(e.currentTarget.getAttribute('data-hover-timeout-id'));
    e.currentTarget.setAttribute(
      'data-hover-timeout-id',
      setTimeout(() => onHover(this), 1000)
    );
    e.currentTarget.setAttribute('data-is-active', true);
  }
}

function onDragOver(e) {
  e.preventDefault();
  e.stopPropagation();
}

function onDragLeave(e) {
  e.preventDefault();
  e.stopPropagation();

  //find the closest drop target root. We use the relatedTarget as the starting point and walk through all the ancestors until we find a element which has DROP_TARGET_DATA_ATTRIBUTE
  //relatedTarget is the element to which the cursor has moved. relatedTarget in Safari is always null so we have to use elementFromPoint to lookup the element under the mouse pointer.
  let dropTargetRoot =
    e.relatedTarget || document.elementFromPoint(e.pageX, e.pageY);
  while (
    dropTargetRoot &&
    dropTargetRoot.getAttribute &&
    !dropTargetRoot.getAttribute(DROP_TARGET_DATA_ATTRIBUTE)
  ) {
    dropTargetRoot = dropTargetRoot.parentNode;
  }

  if (e.currentTarget !== dropTargetRoot) {
    clearTimeout(e.currentTarget.getAttribute('data-hover-timeout-id'));
    e.currentTarget.removeAttribute('data-hover-timeout-id');
    e.currentTarget.removeAttribute('data-is-active');
  }
}

function onDrop(onDropped, { message, contact }, e) {
  e.preventDefault();
  clearTimeout(e.currentTarget.getAttribute('data-hover-timeout-id'));

  const folderId = e.currentTarget.getAttribute(DROP_TARGET_DATA_ATTRIBUTE);
  onDropped({ folderId, message, contact });
}
