import api from '@/api';
import Vue from 'vue';

export const SET_KEYS = 'ENTITIES:KEYS:SET_KEYS';
export const SET_TOTAL = 'ENTITIES:KEYS:SET_TOTAL';
export const DELETE_KEY = 'ENTITIES:KEYS:DELETE_KEY';

export default {
  state: {
    byFingerprint: {},
    total: null,
  },

  getters: {
    keys: (state) => Object.values(state.byFingerprint),
    keysCount: (state, getters) => getters.keys.length,
  },

  actions: {
    // API action
    loadKeys({ commit }, { query } = {}) {
      return api.keys.get({ query }).then(({ keys, total }) => {
        commit(SET_KEYS, { keys });
        commit(SET_TOTAL, { total });
      });
    },
    deleteKey({ commit }, { fingerprint }) {
      return api.keys
        .delete({ fingerprint })
        .then(() => commit(DELETE_KEY, { fingerprint }));
    },
    importKeys(_, { files, keyData }) {
      return api.keys.import({ files, keyData }).then(({ files, key_data }) => {
        return {
          files,
          keyData: key_data,
        };
      });
    },
  },
  mutations: {
    [SET_KEYS](state, { keys }) {
      state.byFingerprint = keys.reduce(
        (all, key) => ({
          ...all,
          [key.fingerprint]: key,
        }),
        {}
      );
    },
    [SET_TOTAL](state, { total }) {
      state.total = total;
    },
    [DELETE_KEY](state, { fingerprint }) {
      Vue.delete(state.byFingerprint, fingerprint);
    },
  },
};
