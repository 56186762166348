import * as types from '../sorting/sorting-mutation-types';
export const DATE_ASCENDING = 'Date ascending';
export const DATE_DESCENDING = 'Date descending';
export const SENDER_ASCENDING = 'Sender ascending';
export const SENDER_DESCENDING = 'Sender descending';
export const RECIPIENT_ASCENDING = 'Recipient ascending';
export const RECIPIENT_DESCENDING = 'Recipient descending';

export const SORT_ORDER = new Map([
  [SENDER_ASCENDING, [{ term: 'sender', order: 'ascending' }]],
  [SENDER_DESCENDING, [{ term: 'sender', order: 'descending' }]],
  [RECIPIENT_ASCENDING, [{ term: 'recipient', order: 'ascending' }]],
  [RECIPIENT_DESCENDING, [{ term: 'recipient', order: 'descending' }]],
  [DATE_ASCENDING, [{ term: 'arrivaldate', order: 'ascending' }]],
  [DATE_DESCENDING, [{ term: 'arrivaldate', order: 'descending' }]],
]);

export const INBOUND_SORT_ORDER = new Map([
  [SENDER_ASCENDING, [{ term: 'sender', order: 'ascending' }]],
  [SENDER_DESCENDING, [{ term: 'sender', order: 'descending' }]],
  [DATE_ASCENDING, [{ term: 'arrivaldate', order: 'ascending' }]],
  [DATE_DESCENDING, [{ term: 'arrivaldate', order: 'descending' }]],
]);

export const OUTBOUND_SORT_ORDER = new Map([
  [RECIPIENT_ASCENDING, [{ term: 'recipient', order: 'ascending' }]],
  [RECIPIENT_DESCENDING, [{ term: 'recipient', order: 'descending' }]],
  [DATE_ASCENDING, [{ term: 'arrivaldate', order: 'ascending' }]],
  [DATE_DESCENDING, [{ term: 'arrivaldate', order: 'descending' }]],
]);

const state = {
  sortOrder: DATE_DESCENDING,
};

const actions = {
  setSortOrder({ commit, state }, { order }) {
    if (order !== state.sortOrder) {
      commit(types.SET_SORT_ORDER, { order });
    }

    return state.sortOrder;
  },
  resetSortOrder({ commit }) {
    commit(types.RESET_SORT_ORDER);
  },
};

const mutations = {
  [types.SET_SORT_ORDER](state, { order }) {
    state.sortOrder = order;
  },
  [types.RESET_SORT_ORDER](state) {
    state.sortOrder = DATE_DESCENDING;
  },
};

export default {
  state,
  actions,
  mutations,
};
