import { setItem } from '@/lib/localStorage';
import * as types from './layout-mutation-types';

export const SPLIT_NONE = 'No split';
export const SPLIT_HORIZONTAL = 'Horizontal split';
export const SPLIT_VERTICAL = 'Vertical split';
export const DENSITY_DEFAULT = 'density_default';
export const DENSITY_COMPACT = 'density_compact';

export const DENSITY_OPTIONS = new Map([
  [DENSITY_DEFAULT, { value: DENSITY_DEFAULT, class: '', groupItems: true }],
  [DENSITY_COMPACT, { value: DENSITY_COMPACT, groupItems: false }],
]);

const state = {
  activeSplit: SPLIT_NONE,
  density: DENSITY_DEFAULT,
};

const getters = {
  densityOption: (state) => DENSITY_OPTIONS.get(state.density),
  isCompactView: (state, getters) =>
    getters.densityOption.value === DENSITY_COMPACT,
  activeSplit: (state) => state.activeSplit,
  isVerticalSplit: (state, getters) => getters.activeSplit === SPLIT_VERTICAL,
};

const actions = {
  async setLayoutSplit({ commit, state }, { layoutSplit }) {
    if (layoutSplit !== state.layoutSplit) {
      await setItem('sm-layout-split', layoutSplit); // Set layout to localStorage
      commit(types.SET_LAYOUT_SPLIT, { layoutSplit });
    }
    return state.layoutSplit;
  },
  async setDensity({ commit }, { density }) {
    await setItem('sm-layout-density', density);
    commit(types.SET_DENSITY, { density });
  },
  async setDefaultSplit({ commit }) {
    await setItem('sm-layout-split', SPLIT_NONE); // Set default layout to localStorage
    commit(types.SET_LAYOUT_SPLIT, { layoutSplit: SPLIT_NONE });
  },
};

const mutations = {
  [types.SET_LAYOUT_SPLIT](state, { layoutSplit }) {
    state.activeSplit = layoutSplit;
  },
  [types.SET_DENSITY](state, { density }) {
    state.density = density;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
