import { AxiosError } from 'axios';

/**
 * Given an axios error, return the api error code if available.
 * Otherwise, returns undefined.
 */
export default function getErrorCode(err: AxiosError): number | null {
  const maybe_code = (err?.response?.data as any)?.code;
  if (typeof maybe_code === 'number') {
    return maybe_code;
  }
  return null;
}
