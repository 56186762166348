import { DirectiveBinding, ObjectDirective } from 'vue';

const focusDirective: ObjectDirective = {
  inserted(el: HTMLElement, binding: DirectiveBinding<boolean>) {
    if (binding.value) {
      window.setTimeout(() => el.focus(), 0);
    }
  },
};
export default focusDirective;
